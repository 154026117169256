import { DetailsContainer } from 'src/types';


export function findDetailsIndex<T>(detailsContainerMapping: DetailsContainer<T>[], sys_id: string) {
    return detailsContainerMapping.findIndex(container => container.sys_id === sys_id);
}



/**
 * Adds a hash to the current window URL (i.e. it modifies a global)
 */
export function addHashToWindowUrl(newHash: string) {
    const url = new URL(`#${newHash}`, window.location.href);
    window.history.replaceState({}, "", url);
}

export function removeHashFromWindowUrl() {
    const url = new URL(window.location.href.split('#')[0]); 
    window.history.replaceState({}, "", url);
}

export function adjustSearchTermInWindowUrl(newSearchTerm:string){
    // adding searchTerm to URL, without using react-router to prevent rerenders
    let urlForUpdate = window.location.href; 
    if(urlForUpdate.includes('searchterm=')) {
        // split on 'searchTerm=' and "&"
        const segments = urlForUpdate.split(/(searchterm=|&)/)
        // search term is the segment after 'searchTerm='
        const searchTermInd = segments.findIndex(segment => segment === "searchterm=") + 1; 
        segments[searchTermInd] =  encodeURIComponent(newSearchTerm);  
        // after update, if new search is empty string, delete 'searchTerm='; 
        if(newSearchTerm === '') segments[searchTermInd - 1] = ''; 
        urlForUpdate = segments.join("");
        // delete ? or & if current last char
        if(urlForUpdate.slice(-1) === "?") urlForUpdate = urlForUpdate.slice(0,-1); 
        if(urlForUpdate.slice(-1) === "&") urlForUpdate = urlForUpdate.slice(0,-1); 
    } else {
        const lastChar = urlForUpdate.slice(-1); 
        urlForUpdate += `${!urlForUpdate.includes("?") ? "?" : 
            lastChar === "&" || lastChar === '?' ? "" :  "&" }searchterm=${encodeURIComponent(newSearchTerm)}`; 
    }
    const url = new URL(urlForUpdate); 
    window.history.replaceState({}, "", url);     
}

export function findSearchTermInWindowUrl(){
    let urlForUpdate = window.location.href; 
    if(urlForUpdate.includes('searchterm=')) {
        // split on 'searchTerm='
        const segments = urlForUpdate.split(/(searchterm=)/)
        // search term is the segment after 'searchTerm='
        const searchTermInd = segments.findIndex(segment => segment === "searchterm=") + 1; 
        return decodeURIComponent(segments[searchTermInd])
    }
    return ''; 
}


/**
 * Shallow copy the desired array, replacing whatever's at the given index with a new item
 */
export function immutablyReplace<T>(arr: T[], idx: number, newItem: T): T[] {
    const firstHalf = arr.slice(Math.min(0, idx), idx);
    const secondHalf = arr.slice(idx + 1);

    return [...firstHalf, newItem, ...secondHalf];
}

export function titleCase(str:string) {
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

export function with2decimals(price: string){
    return (Math.round(parseFloat(price) * 100) / 100).toFixed(2);
}