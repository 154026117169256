import { useLocation } from "react-router-dom";

const SEGMENT_LOOKUP_TABLE = {
    "base": 0,
    "region": 1, 
    "page": 2,
    "u_company_id": 3,
    "sys_id": 4,
    "tab": 5,
    "itemId": -1
} as const;

type Segment = keyof typeof SEGMENT_LOOKUP_TABLE;


/**
 * Pass a key to a url segment, recieve the key's index and current value.  Optionally pass a new value to receive an updated url string.
 * 
 * Created as a place to centralize changes.
 */
export default function useLocationElementExtractor(segment: Segment, newVal?: string): { segmentInd: number, currentValue: string, newUrl?: string } {
    const segmentInd = SEGMENT_LOOKUP_TABLE[segment];
    const location = useLocation();
    const [pathname, hash] = location.pathname.split("#");

    if (segmentInd === -1) return { segmentInd: segmentInd, currentValue: hash, newUrl: newVal ? `${pathname}#${newVal}` : undefined };

    const locationElms = pathname.split("/");
    const currentValue = locationElms[segmentInd] ? locationElms[segmentInd] : "";
    let newUrl;
    if (newVal) {
        if (locationElms[segmentInd]) {
            locationElms[segmentInd] = newVal;
        }
        newUrl = locationElms.join("/");
    }

    return { segmentInd, currentValue, newUrl };
}