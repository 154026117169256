import { Stack, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import { green } from "@mui/material/colors";
import InheritableButtonBase from "../util/InheritableButtonBase";

import type { RecentOrder } from "../../types";

interface Props {
    recentOrders: RecentOrder[];
    showAllResults: boolean;
    onClickFn: (sys_id:string, orderNumber:string) => void;
    isTouchScreen: boolean
}

const RecentOrdersDisplay = (props: Props) => {
    //Get the date two days ago to display a badge for recent orders within the last 2 days
    const today = new Date();
    const twoDaysAgo = new Date(today);
    twoDaysAgo.setDate(today.getDate() - 2);

    const additionalCss = props.isTouchScreen ? {} : { '&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' } };

    return (
        <Box>
            {props.recentOrders.map(
                (recentOrder: RecentOrder, index: number) => {
                    if (props.showAllResults || index < 5)
                        return (
                            <Stack
                                key={recentOrder.number}
                                direction="row"
                                sx={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    props.onClickFn(recentOrder.account.id, recentOrder.number)
                                }
                            >
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "lightgray",
                                        width: 1,
                                    }}
                                >
                                    <InheritableButtonBase sx={{
                                        width: '100%',
                                        borderRadius: '4px',
                                        pb: 0.5,
                                        pt: 1,
                                        px: 0.5,
                                        ...additionalCss
                                    }}>
                                        <Stack sx={{
                                            width: "100%",
                                        }}>
                                            <Stack
                                                sx={{
                                                    width: "100%",
                                                }}
                                                flexDirection="row"
                                                justifyContent="space-between"
                                            >
                                                <Box
                                                    sx={{
                                                        fontSize: "1.2rem",
                                                        color: "gray",
                                                    }}
                                                >
                                                    {recentOrder.account.name}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        fontSize: "1.2rem",
                                                    }}
                                                >
                                                    {recentOrder.u_total_mrr_local_code}
                                                </Box>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                sx={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        fontSize: "1.8rem",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {
                                                        recentOrder.u_sales_person
                                                            .display
                                                    }
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        marginLeft: 1,
                                                    }}
                                                >
                                                    {new Date(
                                                        recentOrder.sys_created_on_UTC_TZ
                                                    ) > twoDaysAgo ? (
                                                        <Chip
                                                            sx={{
                                                                marginX: "4px",
                                                                flexShrink: 2,
                                                                minWidth: "0px",
                                                                overflow: "hidden",
                                                                fontSize: "1.2rem",
                                                                backgroundColor:
                                                                    green[100],
                                                                color: green[800],
                                                            }}
                                                            label={"New"}
                                                            size="small"
                                                        />
                                                    ) : null}
                                                </Box>
                                                {/** Empty div with flexGrow to fill blank space */}
                                                <Box
                                                    sx={{
                                                        flexGrow: 2,
                                                    }}
                                                ></Box>
                                                <Box
                                                    sx={{
                                                        fontSize: "1.8rem",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {Number(recentOrder.u_total_mrr_local)
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                </Box>
                                            </Stack>
                                            <Box
                                                sx={{
                                                    fontSize: "1rem"
                                                }}
                                            >
                                                {`${recentOrder.number} - ${recentOrder.short_description}`}
                                            </Box>
                                        </Stack>
                                    </InheritableButtonBase>
                                </Box>
                            </Stack>
                        );
                    else return null;
                }
            )}
        </Box>
    );
};

export default RecentOrdersDisplay;
