import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ArrowDropUp } from '@mui/icons-material';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CatalogFilterNames } from 'src/types';
import { getFilterCountIcon } from '../AdvancedSearch/FiltersDrawerDisplay';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { grey } from '@mui/material/colors';
import { returnCommas } from 'src/pages/ProductCatalog';


interface ProductCatalogFilterProps {
    choices: string[];
    selected: string[];
    choicesWResults: string[]; 
    onChange: (filterVal: string, filterId: CatalogFilterNames) => void;
    filterId: CatalogFilterNames;
    displayName: string; 
}


export default function ProductCatalogFilter({ choices, selected, choicesWResults, onChange, filterId, displayName }: ProductCatalogFilterProps) {
    const [search, setSearch] = useState(""); 
    const choicesAfterSearch = choices.filter(el => el.toLowerCase().includes(search.toLowerCase())); 
    selected = selected.map(el => returnCommas(el)); 

    // if showing non-viable options in grey font, use this sort.  
    // choicesAfterSearch.sort((a,b) => {
    //     const aHasResultsOrSelected = choicesWResults.includes(a) || selected.includes(a); 
    //     const bHasResultsOrSelected = choicesWResults.includes(b) || selected.includes(b); 
    //     return aHasResultsOrSelected === bHasResultsOrSelected ? a.toLowerCase() < b.toLowerCase() ? -1 : 1 : aHasResultsOrSelected && !bHasResultsOrSelected ? -1 : 1; 
    // })    
    choicesAfterSearch.sort((a,b) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1
    })
    
    return (

        <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ArrowDropUp />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Typography variant="h6">
                        {displayName}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {getFilterCountIcon(selected.length)}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <TextField id="outlined-basic" label={`Filter ${displayName} options...`} variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} sx={{width: '100%', mb: 2}} size="small" />
                <FormGroup>
                    {
                        choicesAfterSearch.map(el => {
                            const skipOption = !choicesWResults.includes(el) && !selected.includes(el.toLowerCase()); 

                            // can omit next line to continue to display "skipped" results in greyed font
                            if(skipOption) return <></>
                            return (
                                <FormControlLabel
                                    key={el}
                                    control={<Checkbox checked={selected.includes(el.toLowerCase())} />}
                                    label={
                                        <Typography sx={skipOption ? {color: grey[600], fontStyle: 'italic'} : {}}>{el}</Typography>
                                    }
                                    onChange={(_e) => onChange(el, filterId)}
                                />

                            )
                            })
                    }
                </FormGroup>
            </AccordionDetails>
        </Accordion>



    )


}