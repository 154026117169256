import { useQuery } from "@tanstack/react-query";
import { CatalogItem, CatalogIndeterminate, ProductIdToFamilyMapIndeterminate, ProductIdToFamilyMap } from "src/types";
import { useBackend, useRegion } from "./useRegion";

export function useProductCatalog(): CatalogIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<CatalogItem[], Error>({
        queryFn: () =>
            backend
                .get<{ result: CatalogItem[] }>(`/getProductCatalog2`)
                .then((res) => res.data.result)
                .then((data) => {
                    data.sort((a: CatalogItem, b: CatalogItem) => {
                        return a.name.toLowerCase().trim() < b.name.toLowerCase().trim() ? -1 : 1;
                    });
                    return data as CatalogItem[];
                }),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["product-catalog", region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}

export function useProductIdToFamilyMap(): ProductIdToFamilyMapIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<ProductIdToFamilyMap, Error>({
        queryFn: () =>
            backend
                .get<{ result: ProductIdToFamilyMap }>(`/getProductIdToFamilyMap`)
                .then((res) => res.data.result),
        queryKey: ["product-id-to-family-map", region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}
