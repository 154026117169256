import { Button, Drawer, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import ProductCatalogFilter from 'src/components/ProductCatalog/Filters';
import ProductCatalogTable, { GridContent, TableHeader } from 'src/components/ProductCatalog/Results';
import { CatalogFilterNames, CatalogItem } from 'src/types';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { grey } from '@mui/material/colors';
import useAppBarHeight from 'src/hooks/useAppBarHeight';
import { ChevronLeft } from '@mui/icons-material';
import OutboundIcon from '@mui/icons-material/Outbound';
import SortIcon from '@mui/icons-material/Sort';
import SortControl from 'src/components/ProductCatalog/SortControl';
import useRegionPath from 'src/hooks/useRegionPath';
import { findSearchTermInWindowUrl } from 'src/util/misc';
import { useProductCatalog } from 'src/hooks/useProductCatalog';

export default function ProductCatalog() {
    const catalog = useProductCatalog();
    if (catalog instanceof Error) throw catalog;

    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const screenSizeMed = useMediaQuery(theme.breakpoints.down("lg"));
    const screenSizeSm = useMediaQuery(theme.breakpoints.down("sm"))
    const drawerWidthPx = "370px";
    const mainContentWidth = screenSizeMed ? "100%" : `calc(100% - ${drawerWidthPx})`;
    const appBarHeight = useAppBarHeight();

    const [searchParams, setSearchParams] = useSearchParams();
    // selections from URL, saved as lowercase
    const productFamilySelections = searchParams.get("product-family")?.split(",").map(el => el.toLowerCase()) || []
    const categorySelections = searchParams.get("category")?.split(",").map(el => el.toLowerCase()) || []
    const subCategorySelections = searchParams.get("sub-category")?.split(",").map(el => el.toLowerCase()) || []
    const preferredVendorSelections = searchParams.get("preferred-vendor")?.split(",").map(el => el.toLowerCase()) || []
    const revenueTypeSelections = searchParams.get("revenue-type")?.split(",").map(el => el.toLowerCase()) || []
    const orderedBy = searchParams.get('ordered-by')?.toLowerCase() as keyof CatalogItem;
    const desc = searchParams.get('desc')
    const navigate = useNavigate();
    const region = useRegionPath();

    // Choices from SN potentially have upper case
    const productFamilyChoices = !catalog ? [] : Array.from(new Set(catalog.map(el => el.u_product_family)))
    const categoryChoices = !catalog ? [] : Array.from(new Set(catalog.map(el => el.u_category)))
    const subCategoryChoices = !catalog ? [] : Array.from(new Set(catalog.map(el => el.u_sub_category))) 
    const preferredVendorChoices = !catalog ? [] : Array.from(new Set(catalog.map(el => el.u_preferred_vendor))) 
    const revenueTypeChoices = !catalog ? [] : Array.from(new Set(catalog.map(el => el.u_revenue))) 

    let groupsLU: groupsLUType = {
        "product-family": { selections: productFamilySelections, choices: productFamilyChoices, snName: "u_product_family" },
        "category": { selections: categorySelections, choices: categoryChoices, snName: "u_category" },
        "sub-category": { selections: subCategorySelections, choices: subCategoryChoices, snName: "u_sub_category" },
        "preferred-vendor": { selections: preferredVendorSelections, choices: preferredVendorChoices, snName: "u_preferred_vendor" },
        "revenue-type": { selections: revenueTypeSelections, choices: revenueTypeChoices, snName: "u_revenue" }
    }

    let filteredCatalog = catalog || [];
    // Page filters
    Object.keys(groupsLU).forEach(key => {
            const selections = groupsLU[key].selections.map(el => returnCommas(el));
            const snName = groupsLU[key].snName;
            filteredCatalog = !selections.length ? filteredCatalog : filteredCatalog.filter(el => selections.includes(el[snName].toLowerCase()))
        })
    if (orderedBy) filteredCatalog = sortCatalog(filteredCatalog, orderedBy, Boolean(desc));

    // choicesWithResults a set from choices, potentionally have upper case
    groupsLU = findChoicesWithResults(groupsLU, catalog || []);

    const badSearchParams: string[] = [];
    badSearchParams.push(...findBadParams(productFamilyChoices, productFamilySelections));
    badSearchParams.push(...findBadParams(categoryChoices, categorySelections));
    badSearchParams.push(...findBadParams(subCategoryChoices, subCategorySelections));
    badSearchParams.push(...findBadParams(preferredVendorChoices, preferredVendorSelections));
    badSearchParams.push(...findBadParams(revenueTypeChoices, revenueTypeSelections));

    const onFilterChange = (filterVal: string, filterName: CatalogFilterNames) => {
        const filterValLower = filterVal.toLowerCase(); 
        const filterValLowerNoCommas = replaceCommas(filterValLower);
        let curSelections = groupsLU[filterName].selections;
        if (curSelections.includes(filterValLowerNoCommas)) {
            curSelections = curSelections.filter(el => el !== filterValLowerNoCommas)
        } else {
            curSelections.push(filterValLowerNoCommas)
        }
        curSelections.length ? searchParams.set(filterName, curSelections.join(",")) : searchParams.delete(filterName);
        // always clear search
        searchParams.delete("searchTerm")
        setSearchParams(searchParams);
    }

    const changeSort = (colName: keyof CatalogItem) => {
        // Sort Control and Filter Headers reference searchParams to adjust their display, but changes to sort order are through this function
        if (orderedBy === colName) {
            desc === null ? searchParams.set('desc', 'true') : searchParams.delete('desc');
            setSearchParams(searchParams);
        } else {
            searchParams.set('ordered-by', colName)
            searchParams.delete('desc');
            setSearchParams(searchParams);
        }
    }

    const controlDrawer = (change?: boolean) => {
        if (change === undefined) return setDrawerOpen(!drawerOpen);
        setDrawerOpen(change)
    }

    const fixedDrawerBorder = (
        <Box
            sx={{
                width: drawerWidthPx,
                position: "fixed",
                top: 0,
                left: 0,
                borderRight: 1,
                borderColor: "divider",
                height: "100%",
                zIndex: (theme) => theme.zIndex.drawer - 1,
                pointerEvents: "none",
            }}
        />
    );

    const filterInfo = [
        { choices: productFamilyChoices, selected: productFamilySelections, choicesWithResults: groupsLU['product-family'].choicesWithResults || [], onChange: onFilterChange, filterId: 'product-family', displayName: "Product Family" },
        { choices: categoryChoices, selected: categorySelections, choicesWithResults: groupsLU['category'].choicesWithResults || [], onChange: onFilterChange, filterId: 'category', displayName: "Category" },
        { choices: subCategoryChoices, selected: subCategorySelections, choicesWithResults: groupsLU['sub-category'].choicesWithResults || [], onChange: onFilterChange, filterId: 'sub-category', displayName: "Sub-Category" },
        { choices: preferredVendorChoices, selected: preferredVendorSelections, choicesWithResults: groupsLU['preferred-vendor'].choicesWithResults || [], onChange: onFilterChange, filterId: 'preferred-vendor', displayName: "Preferred Vendor" },
        { choices: revenueTypeChoices, selected: revenueTypeSelections, choicesWithResults: groupsLU['revenue-type'].choicesWithResults || [], onChange: onFilterChange, filterId: 'revenue-type', displayName: "Revenue Type" },
    ]
    const numFiltersSelected = filterInfo.reduce((sum, f) => sum + f.selected.length, 0);

    const handleReset = () => {
        // only passing "searchTerm=" with no val if it needs to be cleared
        navigate(`/${region}/product-catalog${findSearchTermInWindowUrl() ? "?searchterm=" : ""}`)
    }

    return (
        <Box sx={{ width: 1, display: 'flex' }}>
            {!screenSizeMed && fixedDrawerBorder}
            {
                screenSizeMed ?
                    <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} sx={{
                        maxWidth: drawerWidthPx,
                        "& .MuiDrawer-paper": {
                            maxWidth: drawerWidthPx,
                        },

                    }}>
                        <Box sx={{
                            boxSizing: "border-box",
                            paddingTop: `calc(${appBarHeight}px + 2rem)`,
                            paddingX: 3,
                            width: "100%",
                        }}>
                            <FilterSectionContent resetFilters={handleReset} filters={filterInfo} controlDrawer={controlDrawer} changeSort={changeSort} />
                        </Box>
                    </Drawer>
                    :
                    <Box sx={{ width: `${drawerWidthPx}`, height: `94vh` }}>
                        <Box sx={{
                            px: 2, pb: 1, height: `calc(100% - ${appBarHeight}px)`, paddingTop: 4, overflowY: 'auto',
                        }}>
                            <FilterSectionContent resetFilters={handleReset} filters={filterInfo} changeSort={changeSort} />
                        </Box>
                    </Box>
            }
            <Box sx={{ width: mainContentWidth }}>
                {
                    !catalog ? <LoadingSkeleton screenSizeSm={screenSizeSm} /> :
                        <ProductCatalogTable catalogItems={filteredCatalog} screenSizeMed={screenSizeMed} screenSizeSm={screenSizeSm}
                            controlDrawer={controlDrawer} numFiltersSelected={numFiltersSelected} changeSort={changeSort}
                            badParams={badSearchParams} />
                }
            </Box>
        </Box>
    )
}

interface FilterSectionContents {
    resetFilters: () => void
    filters: {
        choices: string[],
        selected: string[],
        choicesWithResults: string[],
        onChange: (filterVal: string, filterName: CatalogFilterNames) => void,
        filterId: string,
        displayName: string
    }[]
    controlDrawer?: (change?: boolean) => void
    changeSort: (colName: keyof CatalogItem) => void
}

function FilterSectionContent({ resetFilters, filters, controlDrawer, changeSort }: FilterSectionContents){
    const theme = useTheme();
    const screenSizeSm = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <Box sx={{ minWidth: '300px' }}>
            <Controls resetFilters={resetFilters} controlDrawer={controlDrawer} />
            {
                screenSizeSm ?
                    <>
                        <SortHeader />
                        <SortControl changeSort={changeSort} />
                    </> : <></>
            }
            <FilterHeader />
            {
                filters.map((filter) => {
                    return (
                        <ProductCatalogFilter key={filter.filterId} choices={filter.choices} selected={filter.selected} choicesWResults={filter.choicesWithResults}
                            onChange={filter.onChange} filterId={filter.filterId as CatalogFilterNames} displayName={filter.displayName} />
                    )
                })
            }
        </Box>
    )
}

interface ControlsProps {
    resetFilters: () => void
    controlDrawer?: (change?: boolean) => void
}

function Controls({ resetFilters, controlDrawer }: ControlsProps) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 4,
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                {
                    !controlDrawer ? <></> :
                        <IconButton onClick={() => controlDrawer(false)} sx={{ padding: 0 }}>
                            <ChevronLeft />
                        </IconButton>
                }
            </Box>
            <Button variant="outlined" color="error" size="small" onClick={resetFilters}>
                Reset
            </Button>
        </Box>
    )
}

function FilterHeader() {
    return (
        <Box sx={{ display: "flex", flexDirection: "row", my: 4 }}>
            <FilterAltIcon sx={{ color: grey[700], pt: .5 }} />
            <Typography variant="h5" sx={{ color: grey[700] }}>
                Filters
            </Typography>
        </Box>

    )
}

function SortHeader() {
    return (
        <Box sx={{ display: "flex", flexDirection: "row", my: 4 }}>
            <SortIcon sx={{ color: grey[700], pt: .5 }} />
            <Typography variant="h5" sx={{ color: grey[700] }}>
                Sort
            </Typography>
        </Box>
    )
}

interface LoadingSkeletonProps {
    screenSizeSm: boolean
}

function LoadingSkeleton({ screenSizeSm }: LoadingSkeletonProps) {

    return (
        <Box sx={{ px: 2, py: 4 }}>
            <Typography variant={screenSizeSm ? "h6" : "h5"}>Product Catalog</Typography>
            <Skeleton height="80px" />
            <Box sx={{ height: '20px' }} />
            {
                !screenSizeSm ?
                    <TableContainer>
                        <Table size="small" aria-label="a dense table" stickyHeader={true}>
                            <TableHeader changeSort={() => { }} />
                            <TableBody>
                                {Array.from(Array(10).keys()).map((_, ind) => {
                                    return (
                                        <TableRow key={ind}>
                                            <TableCell><Skeleton width="250px" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                            <TableCell><Skeleton width="100%" height="30px" /></TableCell>
                                        </TableRow>
                                    )})}
                            </TableBody>
                        </Table>
                    </TableContainer> :
                    <Box sx={{ p: 1 }}>
                        {
                            [0, 1].map((el, ind) => {
                                return (
                                    <Paper sx={{ p: 1, my: 1 }} key={ind}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <GridContent header="Product Name (Product ID)" body={<Skeleton />} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <GridContent header="Owned By" body={<Skeleton />} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <GridContent header="Product Family" body={<Skeleton />} />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <GridContent header="Category" body={<Skeleton />} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <GridContent header="SubCategory" body={<Skeleton />} />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <GridContent header="Price" body={<Skeleton />} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <GridContent header="Ent. Qty" body={<Skeleton />} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <GridContent header="Pr. Vendor" body={<Skeleton />} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <GridContent header="Revenue Type" body={<Skeleton />} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <GridContent header="SF Link" body={<OutboundIcon />} />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                )
                            })
                        }
                    </Box>
            }
        </Box>
    )
}


function sortCatalog(catalog: CatalogItem[], sortCol: keyof CatalogItem, desc = false) {
    if (sortCol === 'owned_by') {
        return catalog.sort((a, b) => {
            return (a[sortCol].display.trim() < b[sortCol].display.trim() ? -1 : 1) * (desc ? -1 : 1);
        })
    }
    if (sortCol === 'price' || sortCol === 'u_entitlement_quantity') {
        return catalog.sort((a, b) => {
            return (parseFloat(a[sortCol]) < parseFloat(b[sortCol]) ? -1 : 1) * (desc ? -1 : 1);
        })

    }
    return catalog.sort((a, b) => {
        return (a[sortCol].trim() < b[sortCol].trim() ? -1 : 1) * (desc ? -1 : 1);
    });
}

function findBadParams(choicesList: string[], selections: string[]): string[] {
    const badParams: string[] = [];
    const choicesLower = choicesList.map(el => el.toLowerCase());
    selections.forEach(selection => {
        const selectionNoCommas = returnCommas(selection); 
        if (!choicesLower.includes(selectionNoCommas)) badParams.push(selection)
    })
    return badParams;
}

type snNamesInGroups = 'u_product_family' | 'u_category' | 'u_sub_category' | 'u_preferred_vendor' | 'u_revenue';
type groupsLUType = Record<string, { selections: string[], choices: string[], choicesWithResults?: string[], snName: snNamesInGroups }>

function findChoicesWithResults(groupsLU: groupsLUType, catalog: CatalogItem[]) {
    const keys = Object.keys(groupsLU);
    keys.forEach(key => {
        const keysCopy = [...keys];
        // excluding current key to get filtered data set without key's filter
        keysCopy.splice(keysCopy.indexOf(key), 1);
        let filteredCatalog = catalog;
        keysCopy.forEach(key2 => {
            const snName = groupsLU[key2].snName;
            const selections = groupsLU[key2].selections;
            if (selections.length) filteredCatalog = filteredCatalog.filter(el => selections.includes(el[snName].toLowerCase()));
        })
        const snName2 = groupsLU[key].snName as snNamesInGroups;
        groupsLU[key]['choicesWithResults'] = Array.from(new Set(filteredCatalog.map(el => el[snName2])))
    })
    return groupsLU; 
}

function replaceCommas(str: string) {
    return str.replace(/,/g, '`')
}

export function returnCommas(str: string) {
    return str.replace(/`/g, ",")
}
