import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'; 
import Stack from '@mui/material/Stack'; 
import Tooltip from "@mui/material/Tooltip"; 
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { makeMonthCutoff } from '../../util/timeUtils';

import { Invoice } from '../../types';

interface InvoicesTrendProps {
    invoices:Invoice[], 
    referenceDate: Date,
    trendType: trendType
}

type trendType = "last" | "last3" | "last6" 

const mthsLU:{[index in trendType]:{months: number, label: string}} = {
    "last": {
        months: -1, 
        label: "Last Month"
    },
    "last3": {
        months: -3,
        label: "Last 3 Months"
    },  
    "last6": {
        months: -6, 
        label: "Last 6 Months"
    }
}

export default function InvoicesTrend({invoices, referenceDate, trendType}:InvoicesTrendProps) {
    const label = mthsLU[trendType].label; 
    const months = mthsLU[trendType].months; 
    const periodStart = makeMonthCutoff(referenceDate, months); 
    const periodEnd = makeMonthCutoff(referenceDate, 0); 
    const prevMonthStart = makeMonthCutoff(referenceDate, months - 1);
    let invoiceTotal = 0;    
    let previousMthTotal = 0; 
    let currencyCounter = invoices.reduce((accum:{[index:string]: boolean}, el) => {
        if(!accum[el.u_invoice_total_local_code]) accum[el.u_invoice_total_local_code] = true; 
        return accum; 
    }, {})
    const useReference = Object.keys(currencyCounter).length > 1; 
    const currencyCode = !invoices.length ? "USD" : useReference ? invoices[0].u_invoice_total_code : invoices[0].u_invoice_total_local_code; 
    const invoiceTotalSource = useReference ? 'u_invoice_total' : 'u_invoice_total_local'; 
    
    invoices.forEach(el => {
        let elDate = new Date(`${el.u_invoice_date}T12:00:00`); 
        if(periodStart < elDate && periodEnd >= elDate ){
            invoiceTotal += parseFloat(el[invoiceTotalSource]); 
        }
        // calculated for all trend types, but only makes sense for trendType == 'last'.  Wouldn't be correct if used for last3 or last6
        if(prevMonthStart < elDate && periodStart >= elDate){
            previousMthTotal += parseFloat(el[invoiceTotalSource]);
        }
    })
    let percentageChg = Math.round(((invoiceTotal - previousMthTotal) / Math.abs(previousMthTotal))*1000) / 10
    percentageChg = Number.isNaN(percentageChg) ? 0 : percentageChg; 

    const changeColor = invoiceTotal === previousMthTotal ? 'grey' : invoiceTotal < previousMthTotal ? 'red' : 'green'; 
    return (
        <Stack>
            <Stack direction="row" alignItems="center">
                <Box>
                    <PaymentsOutlinedIcon sx={{color: 'green'}} />
                </Box>
                <Box sx={{ml:.5}}>
                    <Typography sx={{fontWeight:'bold', color:'#6A6A6A', fontSize:'small', whiteSpace: 'nowrap'}}>{label}</Typography>
                </Box>
            </Stack>
            <Box sx={{marginTop:'10px'}}>
                <Stack direction="row" alignItems='flex-start'>
                    <Box>
                        <Typography sx={{fontWeight: 'bold', color: '#6A6A6A', fontSize:'2.5rem'}}>
                            {invoiceTotal.toLocaleString()} <Box component="span" sx={{fontSize: '1.4rem'}}>{currencyCode}</Box>
                        </Typography>
                    </Box>
                    {
                        trendType !== 'last' ? <></> : 
                        <Box sx={{marginLeft: '5px', cursor: 'default'}}>
                            <Tooltip title={`${percentageChg}% change vs month prior`} placement="top" > 
                                <Typography sx={{color: changeColor, fontSize: 'small', whiteSpace: 'nowrap'}}>
                                    {percentageChg >= 0 ? "+" : ""}{percentageChg} %
                                </Typography>
                            </Tooltip>
                        </Box>
                    }
                </Stack>
            </Box>
        </Stack>
    );
}

