import { useQuery } from "@tanstack/react-query";
import { CompanyOption, CompanyOptionIndeterminate } from "src/types";
import { useBackend, useRegion } from "./useRegion";

export function useCompanyList(): CompanyOptionIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<CompanyOption[], Error>({
        queryFn: () =>
            backend
                .get<{ result: CompanyOption[] }>(`/getCompanies`)
                .then((res) => res.data.result)
                .then((data) => {
                    data.sort((a: CompanyOption,b: CompanyOption) => {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                    })
                    return data as CompanyOption[];
                }),
        queryKey: ["company-list", region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}
