import React from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";

interface Props {
    menuId: string;
    mobileMenuId: string;
    handleProfileMenuOpen: React.MouseEventHandler;
    handleMobileMenuOpen: React.MouseEventHandler;
}

const UserInfoAndActionsIcons = (props: Props) => {
    return (
        <>
            <Box
                sx={{
                    display: { xs: "none", md: "flex" },
                    flexDirection: "row-reverse",
                }}
            >
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={props.menuId}
                    aria-haspopup="true"
                    onClick={props.handleProfileMenuOpen}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                {/* <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                >
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton> */}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={props.mobileMenuId}
                    aria-haspopup="true"
                    onClick={props.handleMobileMenuOpen}
                    color="inherit"
                    sx={(theme) => ({
                        p: theme.spacing(0.5),
                        pl: { sm: theme.spacing(1) }
                    })}
                >
                    <MoreIcon />
                </IconButton>
            </Box>
        </>
    );
};

export default UserInfoAndActionsIcons;
