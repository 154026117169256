import { useQuery } from "@tanstack/react-query";
import { useBackend, useRegion } from "./useRegion";
import { Contract, ContractDetails, ContractDetailsIntermediate, ContractsIndeterminate } from "src/types";

export function useContracts(companyId: string): ContractsIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<Contract[], Error>({
        queryFn: () => backend.get<{ result: Contract[] }>(`/getContracts/${companyId}`).then((res) => res.data.result),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["contracts", companyId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}



export function useContractDetails(contractId: string): ContractDetailsIntermediate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<ContractDetails[], Error>({
        queryFn: () => backend.get<{ result: ContractDetails[] }>(`/getContractDetails/${contractId}`).then((res) => res.data.result),
        queryKey: ["contractDetails", contractId, region],    
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}