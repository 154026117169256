import { createTheme } from "@mui/material";
  
  declare module '@mui/material/styles' {
    interface Theme {
      maxWidthForPages: string, 
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
      maxWidthForPages?: string
    }
  }

export const usTheme = createTheme({
    palette: {
        primary: {
          main: "#293D74",  // Blue in Thrive Style Guide
        },
        secondary: {
          main: '#C7C8D8',  // Medium Gray in Style Guide
        },
        warning: {
          light: '#fcaca3',
          main: '#F05A28'  // Orange in Style Guide 
        }, 
        info: {
            main: '#0F82C6'  // Medium Blue in Style Guide
        }, 
      },
    maxWidthForPages: '1200px', 
    typography: {
      htmlFontSize: 10,
    },
  });

export const euTheme = createTheme({
  palette: {
      primary: {
        main: "#293D54",  // Blue in Thrive Style Guide
      },
      secondary: {
        main: '#C7C8D8',  // Medium Gray in Style Guide
      },
      warning: {
        light: '#fcaca3',
        main: '#F05A28'  // Orange in Style Guide 
      }, 
      info: {
          main: '#0F82C6'  // Medium Blue in Style Guide
      }, 
    },
  maxWidthForPages: '1200px', 
  typography: {
    htmlFontSize: 10,
  },
}); 