import Box from '@mui/material/Box'; 
import Stack from '@mui/material/Stack'; 
import { grey } from '@mui/material/colors'; 
import FlashingDiv from './FlashingDiv';

export default function CompanyHeaderSkeleton() {

    return (
        <Box sx={{width: '100%'}}>
            <Stack direction="row" alignItems="flex-end" sx={{width: '100%'}} >
                <FlashingDiv sx={{fontSize: '3rem', backgroundColor: grey[200], width: '20rem', borderRadius: 1, marginRight: 2}}>
                    &nbsp;
                </FlashingDiv>
                <FlashingDiv sx={{fontSize: '1.8rem', backgroundColor: grey[200], width: '6rem', borderRadius: 4}}>
                    &nbsp;
                </FlashingDiv>
                {/** Empty div with flexGrow to fill blank space */}
                <Box sx={{flexGrow: 2}}></Box>
            </Stack>
        </Box>
    )   
}