import React, { useEffect, useState } from "react";

import { CompanyOption } from "src/types";
import CompanyBadges, { BadgeObject } from "../About/CompanyBadges";

import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Pagination from "@mui/material/Pagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LoadingSkeleton from "./LoadingSkeleton";
import { CheckboxState, FiltersState } from "./AdvancedSearchDisplay";
import filterAndSortCompanies from "src/util/search-companies";

/**
 * Function that will calculate the result of the filters and search text and return back an filtered array of companies if at least one filter is selected
 */
const filterResult = (
    filterArray: {
        badges: BadgeObject[];
        companyData: CompanyOption;
    }[],
    filtersState: FiltersState,
    searchBarState: string
) => {
    const filterStateKeyValue = Object.entries(filtersState) as [k: string, v: CheckboxState[]][];

    // The WeakMap is a bit of a lazy implementation to get the badges back to the companyData
    const companiesToBadgesWeakMap = new WeakMap(filterArray.map(e => [e.companyData, e.badges]));
    const result = filterAndSortCompanies(filterArray.map(e => e.companyData), searchBarState)
        .map(e => ({ companyData: e, badges: companiesToBadgesWeakMap.get(e) as BadgeObject[] }))
        .filter((company) => {
            let companyIncluded = true;
            // companyIncluded = company.companyData.name.startsWith(searchBarState);

            for (const [k, v] of filterStateKeyValue) {
                while (companyIncluded) {
                    for (const checkbox of v) {
                        if (checkbox.checked && (k === "Status" || k === "Profile" || k === "Strategic" || k === "Channel")) {
                            const found = company.badges.find((item) => item.data === checkbox.name);
                            if (found) {
                                companyIncluded = true;
                                break;
                            } else {
                                companyIncluded = false;
                            }
                        }
                        //Default and Master Agents
                        if (checkbox.checked && k === "Default Agents") {
                            const found = company.companyData.u_default_agent.display === checkbox.name;
                            if (found) {
                                companyIncluded = true;
                                break;
                            } else {
                                companyIncluded = false;
                            }
                        }
                        if (checkbox.checked && k === "Master Agents") {
                            const found = company.companyData.u_default_master_agent.display === checkbox.name;
                            if (found) {
                                companyIncluded = true;
                                break;
                            } else {
                                companyIncluded = false;
                            }
                        }
                        if (checkbox.checked && k === "Support Pod") {
                            const found = company.companyData.u_support_group.display === checkbox.name;
                            if (found) {
                                companyIncluded = true;
                                break;
                            } else {
                                companyIncluded = false;
                            }
                        }
                    }
                    break;
                }
            }

            return companyIncluded;
        });

    return result;
};

export type FilterResultDisplayProps = {
    searchBarState: string;
    setSearchBarState: (state: string) => void;
    onCompanyClickFn: (sys_id: string) => void;
    setFilterDrawerOpen: (state: boolean) => void;
    filtersState: FiltersState;
    filterCount: (filtersState: FiltersState) => number;
    isLoading: boolean;
    companiesDataWithBadges: {
        badges: BadgeObject[];
        companyData: CompanyOption;
    }[];
};

const FilterResultDisplay = (props: FilterResultDisplayProps) => {
    const theme = useTheme();
    const screenSizeSmall = useMediaQuery(theme.breakpoints.down("lg"));

    //filter companies
    let filteredCompanies = filterResult(props.companiesDataWithBadges, props.filtersState, props.searchBarState);

    //Handle pagination of the filtered company results
    const pageSize = 10;
    const pageCount = Math.ceil(filteredCompanies.length / pageSize);
    const [pageNumber, setPageNumber] = useState(1);
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };

    const searchResult = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        props.setSearchBarState(searchText);
    };

    const paginateFilteredCompanies = (
        array: {
            badges: BadgeObject[];
            companyData: CompanyOption;
        }[],
        page_size: number,
        page_number: number
    ) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    };

    useEffect(() => {
        setPageNumber(1);
    }, [props.searchBarState, props.filtersState])

    return (
        <>
            <Box sx={{ py: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {!screenSizeSmall && <Typography variant="h5">Advanced Search</Typography>}
                {screenSizeSmall && <FilterDrawerButton onClick={() => props.setFilterDrawerOpen(true)} />}
                <Box>
                    <Typography variant="h6" sx={{ color: grey[500] }}>
                        {filteredCompanies.length} Results
                    </Typography>
                    {screenSizeSmall && (
                        <Typography variant="h6" sx={{ color: grey[600], fontSize: 15 }}>
                            {props.filterCount(props.filtersState)} Filters
                        </Typography>
                    )}
                </Box>
            </Box>
            <TextField 
                hiddenLabel 
                fullWidth 
                id="filled-hidden-label-normal" 
                placeholder={!props.searchBarState.length ? "Search a company..." : undefined} 
                value={props.searchBarState} 
                variant="outlined" 
                size="small"
                onChange={searchResult}
                disabled={props.isLoading}
            />
            <Paper sx={{ padding: 2, mt: 2 }}>
                {props.isLoading ? (
                    [...Array(pageSize)].map((e, i) => {
                        return (
                            <Paper
                                sx={{
                                    marginBottom: 0.5,
                                    px: 0.5,
                                    py: 1,
                                    color: "gray",
                                    display: "flex",
                                    flexDirection: "row",
                                    height: 35,
                                    backgroundColor: grey[50],
                                    "&:hover": {
                                        backgroundColor: "#EAEAEA",
                                        cursor: "pointer",
                                    },
                                }}
                                key={i}
                                variant="outlined"
                            >
                                <Box
                                    sx={{
                                        width: 0.4,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <LoadingSkeleton count={1}></LoadingSkeleton>
                                </Box>
                                <Box
                                    sx={{
                                        width: 0.6,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <LoadingSkeleton count={1}></LoadingSkeleton>
                                </Box>
                            </Paper>
                        );
                    })
                ) : !filteredCompanies.length ? (
                    <Typography variant="h6" sx={{ fontSize: 15 }}>
                        No results found. Please select different filters.
                    </Typography>
                ) : (
                    paginateFilteredCompanies(filteredCompanies, pageSize, pageNumber).map((item) => {
                        return (
                            <Paper
                                sx={{
                                    marginBottom: 0.5,
                                    px: 0.5,
                                    py: 1,
                                    color: "gray",
                                    display: "flex",
                                    flexDirection: "row",
                                    height: 35,
                                    backgroundColor: grey[50],
                                    "&:hover": {
                                        backgroundColor: "#EAEAEA",
                                        cursor: "pointer",
                                    },
                                }}
                                key={item.companyData.sys_id}
                                variant="outlined"
                                onClick={() => props.onCompanyClickFn(item.companyData.sys_id)}
                            >
                                <Box
                                    sx={{
                                        width: 0.4,
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: '1.4rem'
                                    }}
                                >
                                    {item.companyData.name}
                                </Box>
                                <Box
                                    sx={{
                                        width: 0.6,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <CompanyBadges 
                                        company={item.companyData} 
                                        justifyContentSetting={"flex-end"} 
                                        chipProps={screenSizeSmall
                                            ? { sx: { padding: "0px", fontSize: ".8rem", height: "1rem", paddingY: "1px", marginLeft: 0 } }
                                            : {}
                                        }
                                    />
                                </Box>
                            </Paper>
                        );
                    })
                )}
            </Paper>
            <Box
                sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                {!filteredCompanies.length ? <></> : 
                    <Pagination 
                        sx={{ opacity: 0.85 }} 
                        count={pageCount} 
                        page={pageNumber} 
                        onChange={handlePageChange} 
                        shape="rounded" 
                        size={screenSizeSmall ? "small" : undefined} 
                        />
                }
            </Box>
        </>
    );
};

export default FilterResultDisplay;

export const FilterDrawerButton = (props: { onClick: React.MouseEventHandler<HTMLButtonElement> }) => {
    return (
        <IconButton
            sx={{
                mr: 0,
                p: 0,
                color: grey[700]
            }}
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={props.onClick}
        >
            <Typography fontSize={15} variant="button">
                Filters
            </Typography>
            <ChevronRightIcon />
        </IconButton>
    );
};
