import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';


interface HomePgComponentProps {
    header: string,
    subheader?: string;
    body: React.ReactNode
}

export default function HomePgComponent(props: HomePgComponentProps) {
    const header = props.header;
    const subheader = props.subheader;
    const body = props.body;

    return (
        <Card sx={{ width: "95%", marginY: '10px' }}>
            <CardContent>
                <Typography sx={{ color: 'black' }}>{header}</Typography>
                {
                    subheader ?
                        <Typography sx={{ color: 'gray' }}>{subheader}</Typography> : <></>
                }
                <Box sx={{ marginTop: 2 }} >
                    {body}
                </Box>
            </CardContent>
        </Card>
    );
}


