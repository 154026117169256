import Grid from '@mui/material/Grid';
import { Skeleton, styled } from '@mui/material';

export type InvoiceOrderDetailsSkeletonProps = {
    count: number
}

const CommonSkeleton = styled(Skeleton)({
    fontSize: '2rem',
});


export default function InvoiceOrderDetailsSkeleton(props: InvoiceOrderDetailsSkeletonProps) {
    const count = props.count;
    const listOfSkeletons = [...Array(count)].map((_, index) => <SkeletonGroup key={index} />)
    return (
        <>{listOfSkeletons}</>
    )
}

function SkeletonGroup() {
    const vertCellPad = "8px";
    const horzCellPad = "5px";

    return (
        <>
            <Grid item xs={3} sx={{ paddingY: vertCellPad, paddingX: horzCellPad }}>
                <CommonSkeleton />
            </Grid>
            <Grid item xs={5} sx={{ paddingY: vertCellPad, paddingX: horzCellPad }}>
                <CommonSkeleton />
            </Grid>
            <Grid item xs={4} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, textAlign: 'end' }}>
                <CommonSkeleton />
            </Grid>
        </>
    )
}