import { styled } from "@mui/material";
import { useRouteError, Link } from "react-router-dom";
import { useSupportedRegions } from "src/hooks/useRegion";
import useRegionPath from "src/hooks/useRegionPath";


const ErrorElement = styled('div')({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
});


export default function ErrorPage() {
    const supportedRegions = useSupportedRegions();
    const error = (useRouteError() as unknown as { statusText: string, message: string });
    const regionPath = useRegionPath();
    const homeUrl = supportedRegions.includes(regionPath as any) ? `/${regionPath}/` : '/'

    return (
        <ErrorElement>
            <h1>Oops!</h1>
            <p>Sorry, there was an error: </p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
            <p><Link to={homeUrl}>Return Home?</Link></p>
        </ErrorElement>
    );
}