import Chip from '@mui/material/Chip';
import type { ChipProps } from '@mui/material/Chip';
import Stack from "@mui/material/Stack";
import { orange, grey, blue } from '@mui/material/colors';

import type { CompanyOption, Company } from '../../types';
import type { SxProps, Theme } from '@mui/material';

const badgeWorthStatuses = ['managed churn', 'churn', 'offboarding', 'at risk'];

export interface BadgeObject {
    data: string, 
    type: "StratBadge" | "StatusBadge" | "Chip",
    badgeCategory: "Status" | "Profile" | "Strategic" | "Channel"
}


export function getBadgesForCompany(company: Company | CompanyOption) {
    const badges : BadgeObject[] = [];
    const u_default_agent = company?.u_default_agent?.display
    const u_default_master_agent = company?.u_default_master_agent.display;

    // If it's a supported status
    if (badgeWorthStatuses.includes(company.u_status.toLowerCase())) badges.push(
        {
            data: company.u_status,
            type: "StatusBadge",
            badgeCategory: "Status"
        }
    );

    // All profile
    if (company.u_profile2.display) badges.push(
        {
            data: company.u_profile2.display, 
            type: "Chip",
            badgeCategory: "Profile"
        }
    );

    // if u_channel === 'true', add in Strat. Relationship and Channel badges
    if (company.u_channel === "true") {

        // Strategic Relationship
        if (company.u_channel_partner_drop_down) badges.push(
            {
                data: company.u_channel_partner_drop_down,
                type: "StratBadge",
                badgeCategory: "Strategic"
            }
        )

        // Channel 
        if (company.u_default_agent || company.u_default_master_agent){
            const labelText = [u_default_agent, u_default_master_agent].filter(Boolean).join(' ➡ ');
            if(labelText) badges.push(
                {
                    data: labelText,
                    type: "Chip",
                    badgeCategory: "Channel"
                }
            );
        }

    }

    return badges;
}

interface CompanyBadgesProps {
    company: Company | CompanyOption,
    justifyContentSetting: 'flex-start' | 'flex-end' | 'center',
    chipProps?: ChipProps,
}

export default function CompanyBadges(props: CompanyBadgesProps) {
    const company = props.company;
    const badges = getBadgesForCompany(company);


    //styles
    const justifyContentSetting = props.justifyContentSetting;
    const { sx: userSx = {}, ...remainingChipsProps } = props?.chipProps || {} as ChipProps;
    const mergedSx: SxProps<Theme> = { m: .5, ...userSx };

    //loop thru badges to create React element array
    const badgeElements: JSX.Element[] = [];
    badges.forEach(badge => {
        switch(badge.type) {
            case "StatusBadge":
                // add StatusBadge to array
                badgeElements.push(
                    <StatusBadge status={badge.data} userSx={mergedSx} chipProps={remainingChipsProps} />
                );
                break;
            case "StratBadge":
                // add StratBadge to array
                badgeElements.push(
                    <StratBadge channel={badge.data} userSx={mergedSx} chipProps={remainingChipsProps} />
                );
                break;
            case"Chip":
                // add Chip to array
                badgeElements.push(
                    <Chip label={badge.data} size="small" sx={mergedSx} {...remainingChipsProps} />
                );
                break;
            default:
                console.error("Invalid badge type detected. Please report this bug to dev team.");
        }
    });

    return (
        <Stack direction="row" sx={{ flexWrap: 'wrap', paddingLeft: "-4px" }} justifyContent={justifyContentSetting}>
            {badgeElements.map((badge, index) => ({ ...badge, key: index }))}
        </Stack>
    );
}


type StatusBadgeProps = {
    status: string,
    userSx: SxProps<Theme>,
    chipProps?: Omit<ChipProps, 'sx'>,

}

export function StatusBadge(props: StatusBadgeProps) {
    const status = props.status;
    const userSx = props.userSx;
    const chipProps = props.chipProps;

    const AtRiskCss = {
        backgroundColor: orange[100],
        color: orange[800]
    }

    const OffBoardCss = {
        backgroundColor: grey[200],
        color: grey[500],
    }

    const selectedCss = status.toLowerCase() === 'at risk' ? AtRiskCss :
        ["offboarding", "churned", "managed churn"].includes(status.toLowerCase()) ? OffBoardCss : {};

    return (
        <Chip size="small" label={status} sx={{ ...selectedCss, ...userSx }} {...chipProps} />
    )
}



type StratBadgeProps = {
    channel: string,
    userSx: SxProps<Theme>,
    chipProps?: Omit<ChipProps, 'sx'>,
}

export function StratBadge(props: StratBadgeProps) {
    const userSx = props.userSx;
    const chipProps = props.chipProps;

    const channel = props.channel;
    return ['court', 'audax'].includes(channel.substring(0, 5).toLowerCase()) ?
        <Chip
            label={channel.toUpperCase()}
            size="small"
            sx={{
                ...userSx,
                backgroundColor: blue[100],
                color: blue[800],
            }}
            {...chipProps}
        /> :
        <Chip label={channel.toUpperCase()} size="small" sx={userSx} {...chipProps} />
}