import HomePgComponent from "../components/HomePgComponents/HomePgComponent";
import MyCompaniesDisplay from "../components/HomePgComponents/MyCompaniesDisplay";
import ErrorComponent from "../components/Error/Error";
import RecentOrders from "./RecentOrders";

import MyCompaniesSkeleton from "../components/Skeleton/MyCompaniesSkeleton";
import { Box } from "@mui/material";
import type { SxProps, Theme } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import isTouchScreen from "../hooks/isTouchScreen";
import useRegionPath from "src/hooks/useRegionPath";
import { useCompanyList } from "src/hooks/useCompanyList";
import { useUser } from "src/hooks/useUser";

export default function HomePage() {
    const snUser = useUser();
    const companyList = useCompanyList();

    const navigate = useNavigate();
    const { maxWidthForPages } = useTheme();
    const region  = useRegionPath(); 

    const containerSx: SxProps<Theme> = {
        width: "100%",
        maxWidth: maxWidthForPages,
        margin: 'auto',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    const MyCompaniesComponent = (companyList instanceof Error || snUser instanceof Error)
        ? <ErrorComponent />
        : !snUser || !companyList
            ? <MyCompaniesSkeleton count={5} />
            : <MyCompaniesDisplay
                companies={companyList}
                userSysId={snUser.sys_id}
                nameClickFn={ (comp) => navigate(`/${region}/companies/${comp.u_company_id}/${comp.sys_id}`) }
                companyCtLimit={5}
                viewAllClickFn={() => navigate(`/${region}/my-companies`)}
                isTouchScreen={isTouchScreen()}
            />

    return (
        <Box sx={containerSx}>
            <RecentOrders showAllResults={false} />
            <HomePgComponent
                header="My Companies"
                subheader="Companies where you are the Strategic Lead, Security Lead, Account Owner or Client Success Manager"
                body={MyCompaniesComponent}
            />
        </Box>
    );
}
