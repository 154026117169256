import { useMatches } from "react-router-dom";
import { useRegionAssertively } from "./useRegion";

export const COMPANY_PAGE_ID = 'company-page';

export function useCompanyRoute() {
    const region = useRegionAssertively();
    return useMatches().find(match => match.id === `${COMPANY_PAGE_ID}-${region}`);
}

export function useCompanyIdFromPath() {
    return useCompanyRoute()?.params.sys_id;
}

export function useCompanyIdFromPathAssertively() {
    const companyId = useCompanyIdFromPath();
    if (!companyId) throw new Error("No companyId found in path");
    return companyId;
}