import Box from '@mui/material/Box'; 
import Stack from '@mui/material/Stack'; 
import FlashingDiv from './FlashingDiv';

export type MyCompaniesSkeletonProps = {
    count: number
}


export default function MyCompaniesSkeleton(props: MyCompaniesSkeletonProps){
    const count = props.count; 
    //@ts-ignore
    const listOfSkeletons = Array(count).fill().map((x, index) => <IndSkeleton key={String(index)}/>)
    return (
        <>{listOfSkeletons}</>
    )
}

function IndSkeleton() {

    return (
        <Box sx={{marginX: 2}} data-testid="skeleton-id-for-test">
            <Stack sx={{width: '100%', marginTop: 1}} direction="row" justifyContent="center" alignItems="center">
                <FlashingDiv sx={{fontSize: '2rem', width: '20rem', borderRadius: 1}}>
                    &nbsp;
                </FlashingDiv>
                {/** Empty div with flexGrow to fill blank space */}
                <Box sx={{flexGrow: 2}}></Box>
                <FlashingDiv sx={{ fontSize: '2.5rem', borderRadius: 4, width: '6rem'}}>
                    &nbsp;
                </FlashingDiv>
            </Stack>
        </Box>
    )   
}