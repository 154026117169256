import { useQuery } from "@tanstack/react-query";
import { NotCoveredTask, NotCoveredTaskIntermediate } from "src/types";
import { useBackend } from "./useRegion";

export function useNotCoveredTask(accountSysId: string, start: string, end: string,): NotCoveredTaskIntermediate {
    const backend = useBackend();
    const { status, data, error } = useQuery<NotCoveredTask[], Error>({
        queryFn: () =>
            backend
                .get<{ result: NotCoveredTask[] }>(`/getNotCoveredTasks`
                    + `?accountSysId=${accountSysId}`
                    + `&startDateYYYY-MM-DD=${start}`
                    + `&endDateYYYY-MM-DD=${end}`
                )
                .then((res) => res.data.result),
        queryKey: ["not-covered-task", accountSysId, start, end],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}