import { Box, Skeleton, Tooltip, useTheme } from "@mui/material";
import ShieldIcon from '@mui/icons-material/Shield';
import { useEntitlements } from "src/hooks/useEntitlements";
import { grey } from "@mui/material/colors";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

interface SecurityRetainerProps{
    companyId: string; 
    setSecurityRetainerPct: (pct: number) => void;
}

export default function SecurityRetainer({companyId, setSecurityRetainerPct}: SecurityRetainerProps) {
    const entitlements = useEntitlements(companyId)
    if(entitlements instanceof Error) throw entitlements; 
    if(!entitlements) return <SecurityRetainerLoadingDisplay />

    const entitlementsWLower = entitlements.map(el => {
        return {
            ...el, 
            u_categoryLower: el.u_category ? el.u_category.toLowerCase() : el.u_category,
            u_sub_categoryLower: el.u_sub_category ? el.u_sub_category.toLowerCase() : el.u_sub_category
        }
    })

    const category1 = entitlementsWLower.filter(el => el.u_categoryLower === 'vciso services' && el.u_sub_categoryLower === 'vciso'); 
    const category2 = entitlementsWLower.filter(el => el.u_categoryLower === 'vulnerability' && el.u_sub_categoryLower === 'scanning'); 
    const category3 = entitlementsWLower.filter(el => (el.u_categoryLower === 'siem' && el.u_sub_categoryLower === 'siem with soc') || 
        (el.u_categoryLower === 'endpoint' && el.u_sub_categoryLower === 'edr with soc') || 
        (el.u_categoryLower === 'end user' && el.u_sub_categoryLower === 'end user bundle')); 

    let pct = 0; 
    if(category1.length > 0) pct += 25;
    if(category2.length > 0) pct += 25;
    if(category3.length > 0) pct += 50;
    setSecurityRetainerPct(pct);

    return <SecurityRetainerDisplay hasCat1={category1.length > 0} hasCat2={category2.length > 0} hasCat3={category3.length > 0} />
}

interface SecurityRetainerDisplayProps {
    hasCat1: boolean;
    hasCat2: boolean;
    hasCat3: boolean;
}


function SecurityRetainerDisplay({hasCat1, hasCat2, hasCat3}: SecurityRetainerDisplayProps){

    return (
        <Tooltip title={
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>{hasCat1 ? <CheckIcon sx={{mr:1, fontSize: '18px'}} /> : <ClearIcon sx={{mr:1, fontSize: '18px'}}/>}25% - vCISO</Box>
                <Box sx={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>{hasCat2 ? <CheckIcon sx={{mr:1, fontSize: '18px'}} /> : <ClearIcon sx={{mr:1, fontSize: '18px'}}/>}25% - Attack Surface Mgmt</Box>
                <Box sx={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>{hasCat3 ? <CheckIcon sx={{mr:1, fontSize: '18px'}}/> : <ClearIcon sx={{mr:1, fontSize: '18px'}}/>}50% - Detection & Response</Box>
            </Box>
            }>

            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <ShieldIconWithLabel label='25%' filled={hasCat1}/>
                <ShieldIconWithLabel label='25%' filled={hasCat2}/>
                <ShieldIconWithLabel label='50%' filled={hasCat3}/>
            </Box>


        </Tooltip>
    )
}

interface ShieldIconWithLabelProps{
    label: string; 
    filled: boolean; 
}

function ShieldIconWithLabel({label, filled}: ShieldIconWithLabelProps){
    const theme = useTheme();
    return (
        <Box sx={{position: 'relative', alignSelf: 'flex-start'}}>
            <ShieldIcon sx={{fontSize: '35px', color: filled ? theme.palette.success.main : grey[400] }}/>
            <Box sx={{position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '12px', 
                color: filled ? theme.palette.success.contrastText : grey[300]}}>{label}</Box>
        </Box>
    )
}

function SecurityRetainerLoadingDisplay(){
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', marginTop: -1}}>
            <Skeleton width={25} height={40} sx={{mx:.5}}/>
            <Skeleton width={25} height={40} sx={{mx:.5}}/>
            <Skeleton width={25} height={40} sx={{mx:.5}}/>
        </Box>
    )
}