import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef } from "react";
import useAppBarHeight from "src/hooks/useAppBarHeight";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import Filter4Icon from "@mui/icons-material/Filter4";
import Filter5Icon from "@mui/icons-material/Filter5";
import Filter6Icon from "@mui/icons-material/Filter6";
import Filter7Icon from "@mui/icons-material/Filter7";
import Filter8Icon from "@mui/icons-material/Filter8";
import Filter9Icon from "@mui/icons-material/Filter9";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import Accordion from "@mui/material/Accordion";
import AccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import LoadingSkeleton from "./LoadingSkeleton";
import { BadgeCheckboxState, CheckboxState, FiltersState } from "./AdvancedSearchDisplay";
import { FILTERS_REDUCER_ACTION_TYPES } from "./FILTERS_REDUCER_ACTION_TYPES";

export type FilterDrawerDisplayProps = {
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    drawerType: "permanent" | "temporary" | "persistent" | undefined;
    drawerWidthPx: string;
    filtersState: FiltersState
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, filterName: FILTERS_REDUCER_ACTION_TYPES) => void;
    resetAllFilters: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading: boolean;
};

export const FilterDrawerDisplayAlt = ({ setOpen, drawerWidthPx, drawerType, isOpen, ...props }: FilterDrawerDisplayProps) => {
    const theme = useTheme();
    const appBarHeight = useAppBarHeight();
    const drawerRef = useRef<any>(null);

    // listening for clicks outside About window, picks up more events than the onClose Drawer Prop
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [drawerRef]);

    const fixedDrawerBorder = (
        <Box
            sx={{
                width: drawerWidthPx,
                position: "fixed",
                top: 0,
                left: 0,
                borderRight: 1,
                borderColor: "divider",
                height: "100%",
                zIndex: (theme) => theme.zIndex.drawer - 1,
                pointerEvents: "none",
            }}
        />
    );

    const drawerSxPerm: SxProps<Theme> = {
        maxWidth: drawerWidthPx,
        height: `calc(100vh - ${appBarHeight}px)`,
        "& .MuiDrawer-paper": {
            position: "sticky",
            height: "unset",
            border: "none",
            pl: 4,
        },
        overflowY: "auto",
    };

    const drawerSxTemp: SxProps<Theme> = {
        maxWidth: drawerWidthPx,
        "& .MuiDrawer-paper": {
            maxWidth: drawerWidthPx,
        },
    };

    const drawerContentSxTemp: SxProps<Theme> = {
        boxSizing: "border-box",
        paddingTop: `calc(${appBarHeight}px + 2rem)`,
        paddingX: 3,
        width: "100%",
    };

    const drawerContentSxPerm: SxProps<Theme> = {
        boxSizing: "border-box",
        paddingTop: 4,
        paddingRight: 3,
        paddingBottom: 1,
        width: "100%",
    };

    return (
        <>
            {drawerType === "permanent" && fixedDrawerBorder}
            <Drawer
                ref={drawerRef}
                anchor="left"
                variant={drawerType as any} //conditional if large view do permanent
                open={isOpen}
                sx={drawerType === "permanent" ? drawerSxPerm : drawerSxTemp}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Box sx={drawerType === "permanent" ? drawerContentSxPerm : drawerContentSxTemp}>
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mb: 4,
                            }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                {drawerType === "temporary" ? (
                                    <IconButton onClick={() => setOpen(false)} sx={{ px: 0, py: 0 }}>
                                        {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                    </IconButton>
                                ) : null}
                                <FilterAltIcon sx={{ color: grey[700], pt: 0.5 }} />
                                <Typography variant="h5" sx={{ color: grey[700] }}>
                                    Filters
                                </Typography>
                            </Box>
                            <Button variant="outlined" color="error" size="small" onClick={props.resetAllFilters}>
                                Reset
                            </Button>
                        </Box>
                        <BadgeCheckboxDrawer
                            isLoading={props.isLoading}
                            display="Status"
                            choices={props.filtersState.Status}
                            onChange={(event) => props.handleCheckboxChange(event, FILTERS_REDUCER_ACTION_TYPES.Status)}
                            AccordionSummaryProps={{ 'aria-controls': "panel1a-content", id: "panel1a-header" }}
                        />
                        <BadgeCheckboxDrawer
                            isLoading={props.isLoading}
                            display="Profile"
                            choices={props.filtersState.Profile}
                            onChange={(event) => props.handleCheckboxChange(event, FILTERS_REDUCER_ACTION_TYPES.Profile)}
                            AccordionSummaryProps={{ 'aria-controls': "panel2a-content", id: "panel2a-header" }}
                        />
                        <BadgeCheckboxDrawer
                            isLoading={props.isLoading}
                            display="Strategic"
                            choices={props.filtersState.Strategic}
                            onChange={(event) => props.handleCheckboxChange(event, FILTERS_REDUCER_ACTION_TYPES.Strategic)}
                            AccordionSummaryProps={{ 'aria-controls': "panel3a-content", id: "panel3a-header" }}
                        />
                        <BadgeCheckboxDrawer
                            isLoading={props.isLoading}
                            display="Channel"
                            choices={props.filtersState.Channel}
                            onChange={(event) => props.handleCheckboxChange(event, FILTERS_REDUCER_ACTION_TYPES.Channel)}
                            AccordionSummaryProps={{ 'aria-controls': "panel4a-content", id: "panel4a-header" }}
                        />
                        <CheckboxDrawer
                            isLoading={props.isLoading}
                            display="Default Agents"
                            choices={props.filtersState["Default Agents"]}
                            onChange={(event) => props.handleCheckboxChange(event, FILTERS_REDUCER_ACTION_TYPES["Default Agents"])}
                            AccordionSummaryProps={{ 'aria-controls': "panel5a-content", id: "panel5a-header" }}
                        />
                        <CheckboxDrawer
                            isLoading={props.isLoading}
                            display="Master Agents"
                            choices={props.filtersState["Master Agents"]}
                            onChange={(event) => props.handleCheckboxChange(event, FILTERS_REDUCER_ACTION_TYPES["Master Agents"])}
                            AccordionSummaryProps={{ 'aria-controls': "panel6a-content", id: "panel6a-header" }}
                        />
                        <CheckboxDrawer
                            isLoading={props.isLoading}
                            display="Support Pod"
                            choices={props.filtersState["Support Pod"]}
                            onChange={(event) => props.handleCheckboxChange(event, FILTERS_REDUCER_ACTION_TYPES["Support Pod"])}
                            AccordionSummaryProps={{ 'aria-controls': "panel7a-content", id: "panel7a-header" }}
                        />
                    </>
                </Box>
            </Drawer>
        </>
    );
};


interface ISingleDrawerProps {
    isLoading: boolean;
    display: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    AccordionSummaryProps?: AccordionSummaryProps;
}

interface ISingleDrawerBadgeCheckboxProps extends ISingleDrawerProps {
    choices: BadgeCheckboxState[];
}

interface ISingleDrawerCheckboxProps extends ISingleDrawerProps {
    choices: CheckboxState[];
}


function BadgeCheckboxDrawer({ isLoading, display, choices, onChange, AccordionSummaryProps }: ISingleDrawerBadgeCheckboxProps) {
    return (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary sx={{ backgroundColor: grey[50] }} expandIcon={!isLoading ? <ExpandMoreIcon /> : null} {...AccordionSummaryProps}>
                <Typography variant="h6" sx={{ mr: 1, width: 1 }}>
                    {isLoading ? <LoadingSkeleton count={1}></LoadingSkeleton> : display}
                </Typography>
                {getFilterCountIcon(choices.filter((checkbox) => checkbox.checked).length)}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: grey[50] }}>
                <Paper sx={{ p: 1 }}>
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mb: 1,
                                }}
                            >
                                {choices.map((checkbox, index) => {
                                    return checkbox ? (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        "& .MuiSvgIcon-root": {
                                                            fontSize: 18,
                                                        },
                                                    }}
                                                    checked={checkbox.checked}
                                                    onChange={onChange}
                                                    name={index.toString()}
                                                />
                                            }
                                            label={checkbox.element}
                                        />
                                    ) : null;
                                })}
                            </Box>
                        </FormGroup>
                    </FormControl>
                </Paper>
            </AccordionDetails>
        </Accordion>
    )
}




function CheckboxDrawer({ isLoading, display, choices, onChange, AccordionSummaryProps }: ISingleDrawerCheckboxProps) {
    return (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary sx={{ backgroundColor: grey[50] }} expandIcon={!isLoading ? <ExpandMoreIcon /> : null} {...AccordionSummaryProps}>
                <Typography variant="h6" sx={{ mr: 1, width: 1 }}>
                    {isLoading ? <LoadingSkeleton count={1}></LoadingSkeleton> : display}
                </Typography>
                {getFilterCountIcon(choices.filter((checkbox) => checkbox.checked).length)}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: grey[50] }}>
                <Paper sx={{ p: 1 }}>
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                            {choices.map((checkbox, idx) => checkbox && (
                                <FormControlLabel
                                    key={checkbox.name}
                                    control={
                                        <Checkbox
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: 18,
                                                },
                                            }}
                                            checked={checkbox.checked}
                                            onChange={onChange}
                                            name={idx.toString()}
                                        />
                                    }
                                    label={checkbox.name}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Paper>
            </AccordionDetails>
        </Accordion>
    );
}

export const getFilterCountIcon = (count: number) => {
    switch (true) {
        case count === 1:
            return <Filter1Icon />;
        case count === 2:
            return <Filter2Icon />;
        case count === 3:
            return <Filter3Icon />;
        case count === 4:
            return <Filter4Icon />;
        case count === 5:
            return <Filter5Icon />;
        case count === 6:
            return <Filter6Icon />;
        case count === 7:
            return <Filter7Icon />;
        case count === 8:
            return <Filter8Icon />;
        case count === 9:
            return <Filter9Icon />;
        case count > 9:
            return <Filter9PlusIcon />;
        default:
            return null;
    }
};
