import { useQuery } from "@tanstack/react-query";
import { useBackend, useRegion } from "./useRegion";
import { Company, CompanyIndeterminate } from "src/types";

export function useCompany(companyId: string): CompanyIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<Company, Error>({
        queryFn: () => backend.get<{ result: Company }>(`/getCompany/${companyId}`).then((res) => res.data.result), 
        queryKey: ["company", companyId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}
