import { useQuery } from "@tanstack/react-query";
import { Order, OrderDetails, OrderDetailsIndeterminate, OrdersIndeterminate } from "src/types";
import { useBackend, useRegion } from "./useRegion";

export function useOrders(companyId: string): OrdersIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<Order[], Error>({
        queryFn: () => backend.get<{ result: Order[] }>(`/getOrders/${companyId}`).then((res) => res.data.result),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["orders", companyId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}

export function useOrderDetails(orderId: string): OrderDetailsIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<OrderDetails[], Error>({
        queryFn: () => backend.get<{ result: OrderDetails[] }>(`/getOrderDetails/${orderId}`).then((res) => res.data.result),
        queryKey: ["orderDetails", orderId, region],    
        staleTime: Infinity,
    }); 

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}