import { useRef, useEffect } from 'react';
import { CompanyIndeterminate } from '../../types';

import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import AboutSkeleton from '../../components/Skeleton/AboutSkeleton';
import AboutContent from '../About/AboutContent';
import useAppBarHeight from '../../hooks/useAppBarHeight';
import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export type AboutInfoDrawerProps = {
    companyData: CompanyIndeterminate,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    drawerType: "permanent" | "temporary" | "persistent" | undefined,
    drawerWidthPx: string
}

const AboutInfoDrawer = ({ setOpen, drawerWidthPx, companyData, drawerType, isOpen }: AboutInfoDrawerProps) => {
    const theme = useTheme();
    const appBarHeight = useAppBarHeight();
    const drawerRef = useRef<any>(null);
    const mapModalRef = useRef<any>(null); 

    // listening for clicks outside About window, picks up more events than the onClose Drawer Prop
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (!drawerRef.current?.contains(event.target) && !mapModalRef.current?.contains(event.target))  {
                setOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [drawerRef, setOpen]);


    const fixedDrawerBorder = (
        <Box sx={{
            width: drawerWidthPx,
            position: 'fixed',
            top: 0,
            right: 0,
            borderLeft: 1,
            borderColor: 'divider',
            height: '100%',
            zIndex: (theme) => theme.zIndex.drawer - 1,
        }} />
    );

    const drawerSxPerm: SxProps<Theme> = {
        maxWidth: drawerWidthPx,
        height: "100%",
        '& .MuiDrawer-paper': {
            position: 'sticky',
            height: "unset",
            border: "none"
        }
    }

    const drawerSxTemp: SxProps<Theme> = {
        maxWidth: drawerWidthPx,
        '& .MuiDrawer-paper': {
            maxWidth: drawerWidthPx,
        }
    };

    const drawerContentSxTemp: SxProps<Theme> = {
        boxSizing: "border-box",
        paddingTop: `calc(${appBarHeight}px + 2rem)`,
        paddingX: 3,
        width: "100%"
    };

    const drawerContentSxPerm: SxProps<Theme> = {
        boxSizing: "border-box",
        paddingTop: 1,
        paddingX: 2,
        width: "100%"
    };

    return (
        <>
            {drawerType === 'permanent' && fixedDrawerBorder}
            <Drawer
                ref={drawerRef}
                anchor="right"
                variant={drawerType as any} //conditional if large view do permanent
                open={isOpen}
                sx={drawerType === 'permanent' ? drawerSxPerm : drawerSxTemp}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Box sx={drawerType === 'permanent' ? drawerContentSxPerm : drawerContentSxTemp}>
                    <>
                        {drawerType === 'temporary' ?
                            <IconButton onClick={() => setOpen(false)} sx={{ px: 0, mb: 1 }}>
                                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                            : <Box mb={1} />
                        }
                        {
                            companyData instanceof Error ?
                                <Box sx={{ marginX: "25px", fontSize: '1.4rem', textAlign: 'center' }}>
                                    An error occurred loading the company details.  Please refresh the page to try again.
                                </Box> : 
                                !companyData  ? 
                                    <AboutSkeleton /> : 
                                    <AboutContent company={companyData} mapModalRef={mapModalRef}/>
                                
                        }
                    </>
                </Box>
            </Drawer>
        </>
    );
};

export default AboutInfoDrawer; 