import React from "react";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";

import AccountCircle from "@mui/icons-material/AccountCircle";

interface Props {
    mobileMenuId: string;
    mobileMoreAnchorEl: null | HTMLElement;
    handleProfileMenuOpen: React.MouseEventHandler;
    handleMobileMenuClose: React.MouseEventHandler;
}

const MobileMenu = (props: Props) => {
    const isMobileMenuOpen = Boolean(props.mobileMoreAnchorEl);

    return (
        <>
            <Menu
                anchorEl={props.mobileMoreAnchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                id={props.mobileMenuId}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={isMobileMenuOpen}
                onClose={props.handleMobileMenuClose}
            >
                {/* <MenuItem>
                    <IconButton
                        size="large"
                        aria-label="show 4 new mails"
                        color="inherit"
                    >
                        <Badge badgeContent={4} color="error">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <p>Messages</p>
                </MenuItem> */}
                <MenuItem onClick={props.handleProfileMenuOpen}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        </>
    );
};

export default MobileMenu;
