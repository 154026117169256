import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';



// ButtonBase is already meant to be unstyled, but it still comes with a couple styles
// that are undesirable for our purposes, e.g. text-center and font-family. This instead
// sets those values to inherit.
const InheritableButtonBase = styled(ButtonBase)({
    textAlign: 'inherit',
    fontFamily: 'inherit'
});

export default InheritableButtonBase;