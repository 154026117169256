import { Box, Skeleton } from "@mui/material";


export default function ServicesLoading(){

    const addBgColor = {backgroundColor: "grey"}; 

    const categoryBox = (
        <Box sx={{height: '140px', width: '100%', color: 'white', ...addBgColor, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', pt:4}}>
                <Box sx={{px:.5}}><Skeleton variant="rounded" width={40} height={50} sx={{my:.5}} /></Box>
                <Box sx={{px: .5, textAlign: 'center'}}>
                    <Skeleton variant="rectangular" width={50} height={20} sx={{my:.5}} />
                </Box>
            </Box>
            <Box sx={{px: .5, alignSelf: 'end', minHeight: '20px'}}>
                <Skeleton variant="rectangular" width={20} height={12} sx={{my:.5}} />
            </Box>
        </Box>
    )

    return (
        <Box sx={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'stretch', spacing: '1'}}>
            {
                [...Array(10).keys()].map(el => {
                    return (
                        <Box sx={{flexGrow: 1, flexBasis: '18%', margin: '2px'}} key={`services-category-loading-${el}`}>
                            {categoryBox}
                        </Box>    
                    )
                })
            }
        </Box>
    )

}
