import React from "react";
import { Switch } from "@mui/material"; 
import { styled } from '@mui/material/styles';

interface RegionSwitchProps {
    handleChangeFn: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    checked: boolean
}

const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 12,
        height: 16,
      },
      '&:before': {
        content: '"🇺🇸"',
        left: 14,
      },
      '&:after': {
        content: '"🇪🇺"',
        right: 14,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

export default function RegionSwitch({handleChangeFn, checked}:RegionSwitchProps) {

  return (
    <StyledSwitch  color="default" onChange={handleChangeFn} checked={checked} />
  );
}



