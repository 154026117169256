import { grey } from '@mui/material/colors'; 
import { styled } from '@mui/material';
import { keyframes } from '@emotion/react';

const myKeyframe = keyframes`
  0%  {background-color: #E4E6EB;}
  50%  {background-color: #fafafa;}
  100%  {background-color: #E4E6EB;}
  `;


const FlashingDiv = styled('div')({
    backgroundColor: grey[200],
    animation: `${myKeyframe} 3s infinite ease`
  });

export default FlashingDiv; 
