export enum FILTERS_REDUCER_ACTION_TYPES {
    "Status" = "Status",
    "Profile" = "Profile",
    "Strategic" = "Strategic",
    "Channel" = "Channel",
    "Default Agents" = "Default Agents",
    "Master Agents" = "Master Agents",
    "Support Pod" = "Support Pod",
    "RESET" = "RESET"
}
