/**
 * @module
 * Module for communicating to the backend
 */

import axios, { AxiosInstance } from "axios";
import pca from "./auth";

const { REACT_APP_BE_BASE_URL, REACT_APP_EU_BE_BASE_URL,  REACT_APP_AZURE_SCOPES } = process.env;

const backend = axios.create({
    baseURL: REACT_APP_BE_BASE_URL,
    timeout: 61000
});

const euBackend = axios.create({
    baseURL: REACT_APP_EU_BE_BASE_URL,
    timeout: 61000
})

addInterceptor(backend); 
addInterceptor(euBackend); 

export { backend, euBackend }; 


function addInterceptor(backend:AxiosInstance):void{

    backend.interceptors.request.use(async ({ headers={}, ...config }) => {
        const { accessToken } = await pca.acquireTokenSilent({
            account: pca.getActiveAccount()!,
            scopes: REACT_APP_AZURE_SCOPES!.split(",").map(s => s.trim())
        });
    
        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${accessToken}`
            },
            ...config
        };
    });
    

}
