import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box"; 
import Stack from "@mui/material/Stack"; 
import Button from "@mui/material/Button"; 
import CompanyBadges from '../About/CompanyBadges';
import InheritableButtonBase from '../util/InheritableButtonBase';

import type { CompanyOption } from '../../types';

interface MyCompaniesDisplayProps {
    companies: CompanyOption[] | Error,
    userSysId: string,
    /** callback to execute on click of a listed company */
    nameClickFn: (company: CompanyOption) => void,
    companyCtLimit?: number,
    /** 
     * callback to execute on click of the "view all" button if the user
     * provides a companyCtLimit and the number of companies exceeds that limit
     * */
    viewAllClickFn?: () => void
    isTouchScreen: boolean
}






export default function MyCompaniesDisplay(props: MyCompaniesDisplayProps) {
    const companies = props.companies;
    const userSysId = props.userSysId;
    const nameClickFn = props.nameClickFn;
    const companyCtLimit = props.companyCtLimit;
    const viewAllClickFn = props.viewAllClickFn || (() => { });

    if (companies instanceof Error) return <Box><i>An error occurred loading companies.</i></Box>;
    const userCompanies = companies.filter(el => {
        return userSysId !== "" && (
            el.u_spc.id === userSysId || el.u_ae.id === userSysId || el.u_cem.id === userSysId || el.u_security_lead.id === userSysId
        );
    });

    userCompanies.sort((a, b) => {
        return a.u_status === "At Risk" && b.u_status !== "At Risk" ? -1 : 1;
    });

    const additionalCss = props.isTouchScreen ? {} : {'&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' }}

    const companyDomElms = userCompanies.map((company, ind) => {
        if (companyCtLimit && ind + 1 > companyCtLimit) return null
        return (
            <InheritableButtonBase 
                sx={{width: '100%', borderRadius: '4px', paddingX: '5px', paddingY: '4px', ...additionalCss}}
                key={ind}
            >
                <Box
                    sx={{ cursor: 'pointer', width: '100%' }}
                    onClick={() => nameClickFn(company)}
                >
                    <Stack direction="row" spacing={1} alignItems="center" >
                        <Typography>{company.name}</Typography>
                        <Box id="EmptyBoxToPushChipsToRight" sx={{ flexGrow: 1 }}></Box>
                        <CompanyBadges company={company} justifyContentSetting='flex-end' />
                    </Stack>
                </Box>
            </InheritableButtonBase>
        );
    });


    return (
        <>
            {!userCompanies.length
                ? <Box>No results.</Box>
                : <Box>
                    {companyDomElms}
                    {companyCtLimit && userCompanies.length > companyCtLimit
                        ? <Stack alignItems="center">
                            <Button
                                sx={{ marginTop: "10px" }}
                                variant="contained"
                                onClick={() => viewAllClickFn()}
                                data-testid="moreCompaniesTest"
                            >
                                {`Showing ${companyCtLimit} ${companyCtLimit === 1 ? "Company" : "Companies"}.  View All ${userCompanies.length}`}
                            </Button>
                        </Stack>
                        : <></>
                    }
                </Box>
            }
        </>
    );
}