import { useLocation, useNavigate } from "react-router-dom";
import AdvancedSearchDisplayAlt, { BadgeCheckboxState, FiltersState } from "src/components/AdvancedSearch/AdvancedSearchDisplay";
import { BadgeObject, getBadgesForCompany, StatusBadge, StratBadge } from "src/components/About/CompanyBadges";
import { CompanyOption } from "src/types";
import { SxProps, Theme } from "@mui/material/styles";
import Chip, { ChipProps } from "@mui/material/Chip";
import useRegionPath from "src/hooks/useRegionPath";

import type { CheckboxState } from "src/components/AdvancedSearch/AdvancedSearchDisplay";
import { useCompanyList } from "src/hooks/useCompanyList";

type CompaniesAndBadges = { badges: BadgeObject[]; companyData: CompanyOption };

const AdvancedSearch = () => {
    const qryStrSearch = new URLSearchParams(useLocation().search).get("search");
    const region = useRegionPath();

    //Theme settings
    const { sx: userSx = {}, ...remainingChipsProps } = {} as ChipProps;
    const mergedSx: SxProps<Theme> = { m: 0.5, ...userSx };

    //Get all companies' data from context
    const companiesData = useCompanyList();
    if (companiesData instanceof Error) throw companiesData;

    //Get badges for each company
    const companiesDataWithBadges: CompaniesAndBadges[] = companiesData
        ? companiesData.map((comp) => ({ badges: getBadgesForCompany(comp), companyData: comp }))
        : [];

    /**
     * Filter Data Isolation:
     * Isolate filter data for state initialization
     */
    //FILTER 1 - BADGES:
    //Isolate all the badge types and create the checkboxes's states for them
    const badgeMap = new Map<string, BadgeObject>();
    companiesDataWithBadges.forEach((item) => {
        item.badges.forEach((badge) => {
            //check if badge is already in "set"
            if (!badgeMap.has(badge.data)) badgeMap.set(badge.data, badge);
        });
    });

    //FILTERS 2 & 3 - DEFAULT AGENTS & MASTER AGENTS:
    //Isolate all the default agents & master agents and create the checkboxes' states for each category's items
    const defaultAgentSet: CheckboxState[] = [];
    const defaultMasterAgentSet: CheckboxState[] = [];
    companiesDataWithBadges.forEach((item) => {
        if (item.companyData.u_default_agent.display !== "") {
            if (!defaultAgentSet.find((setItem) => setItem.name === item.companyData.u_default_agent.display)) {
                defaultAgentSet.push({ name: item.companyData.u_default_agent.display, checked: false });
            }
        }
        if (item.companyData.u_default_master_agent.display !== "") {
            if (!defaultMasterAgentSet.find((setItem) => setItem.name === item.companyData.u_default_master_agent.display)) {
                defaultMasterAgentSet.push({ name: item.companyData.u_default_master_agent.display, checked: false });
            }
        }
    });
    const defaultSupportPod = Array.from(new Set(companiesData?.map(el => el.u_support_group.display))).map(el => { return {name: el, checked: false}}).sort((a,b) => a.name < b.name ? -1 : 1)

    const badgeSetAsArr = Array.from(badgeMap.values());
    const initialFiltersState: FiltersState = {
        Status: transformToBadgeCheckboxState(
            badgeSetAsArr.filter((item) => item.badgeCategory === "Status"),
            mergedSx,
            remainingChipsProps
        ),
        Profile: transformToBadgeCheckboxState(
            badgeSetAsArr.filter((item) => item.badgeCategory === "Profile"),
            mergedSx,
            remainingChipsProps
        ),
        Strategic: transformToBadgeCheckboxState(
            badgeSetAsArr.filter((item) => item.badgeCategory === "Strategic"),
            mergedSx,
            remainingChipsProps
        ),
        Channel: transformToBadgeCheckboxState(
            badgeSetAsArr.filter((item) => item.badgeCategory === "Channel"),
            mergedSx,
            remainingChipsProps
        ),
        "Default Agents": defaultAgentSet,
        "Master Agents": defaultMasterAgentSet,
        "Support Pod": defaultSupportPod
    };

    //Handle redirect to company page on click of a company
    const navigate = useNavigate();
    const onCompanyClickFn = (sys_id: string) => {
        // u_company_id is cosmetic and doesn't affect navigation.  If not available, load with an underscore char in its place.
        let u_company_id = "_";
        if (companiesData && !(companiesData instanceof Error)) {
            const company = companiesData.find((el) => el.sys_id === sys_id);
            u_company_id = company && company.u_company_id ? company.u_company_id : "_";
        }
        navigate(`/${region}/companies/${u_company_id}/${sys_id}`);
    };

    return <AdvancedSearchDisplayAlt
        key={companiesData === null ? 1 : 0}
        companiesData={companiesData}
        companiesDataWithBadges={companiesDataWithBadges}
        onCompanyClickFn={onCompanyClickFn}
        initialFiltersState={initialFiltersState}
        initSearchBarState={qryStrSearch ?? ""}
    />;
};



export default AdvancedSearch;



/**
 * Function that tranforms array of badges into a array of badge checkbox state objects
 */
function transformToBadgeCheckboxState(badgeSet: BadgeObject[], mergedSx: SxProps<Theme>, remainingChipsProps: ChipProps) {
    return badgeSet.reduce((result, badge: BadgeObject) => {
        switch (badge.type) {
            case "StatusBadge":
                // add StatusBadge to array
                result.push({
                    name: badge.data,
                    element: <StatusBadge status={badge.data} userSx={mergedSx} chipProps={remainingChipsProps} />,
                    checked: false,
                    group: badge.badgeCategory,
                });
                break;
            case "StratBadge":
                // add StratBadge to array
                result.push({
                    name: badge.data,
                    element: <StratBadge channel={badge.data} userSx={mergedSx} chipProps={remainingChipsProps} />,
                    checked: false,
                    group: badge.badgeCategory,
                });
                break;
            case "Chip":
                // add Chip to array
                if (!badge.data.includes("➡")) {
                    result.push({
                        name: badge.data,
                        element: <Chip label={badge.data} size="small" sx={mergedSx} {...remainingChipsProps} />,
                        checked: false,
                        group: badge.badgeCategory,
                    });
                    break;
                } else break;
            default:
                console.error("Invalid badge type detected. Please report this bug to dev team.");
                break;
        }
        return result;
    }, [] as BadgeCheckboxState[]);
}
