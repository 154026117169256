import { useQuery } from "@tanstack/react-query";
import { useBackend, useRegion } from "./useRegion";
import { EntitlementWProductInfo, EntitlementsWProductInfoIndeterminate } from "src/types";

export function useEntitlements(companyId: string): EntitlementsWProductInfoIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<EntitlementWProductInfo[], Error>({
        queryFn: () => backend.get<{ result: EntitlementWProductInfo[] }>(`/getEntitlementsWProd/${companyId}`).then((res) => res.data.result),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["entitlements", companyId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}
