import React from "react";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import { useMsal } from "@azure/msal-react";

interface Props {
    menuId: string;
    anchorEl: null | HTMLElement;
    handleMenuClose: React.MouseEventHandler;
}

const MoreMenu = (props: Props) => {
    const isMenuOpen = Boolean(props.anchorEl);

    //Handle Signout
    const { instance } = useMsal();
    const handleSignOut = (event: React.MouseEvent) => {
        instance.logoutRedirect();
        props.handleMenuClose(event);
    };

    return (
        <>
            <Menu
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                id={props.menuId}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={isMenuOpen}
                onClose={props.handleMenuClose}
            >
                {/* <MenuItem onClick={props.handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={props.handleMenuClose}>My Account</MenuItem> */}
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </Menu>
        </>
    );
};

export default MoreMenu;
