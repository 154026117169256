import MyCompaniesDisplay from '../components/HomePgComponents/MyCompaniesDisplay'; 
import { Box } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import HomePgComponent from '../components/HomePgComponents/HomePgComponent'; 
import MyCompaniesSkeleton from '../components/Skeleton/MyCompaniesSkeleton'; 
import isTouchScreen from '../hooks/isTouchScreen';
import useRegionPath from 'src/hooks/useRegionPath';
import { useCompanyList } from 'src/hooks/useCompanyList';
import { useUser } from 'src/hooks/useUser';


export default function MyCompaniesPage() {
    const navigate = useNavigate();
    const { maxWidthForPages } = useTheme();
    const regionPath = useRegionPath(); 

    const snUser = useUser();
    const companyList = useCompanyList();

    const containerSx: SxProps<Theme> = {
        width: '100%',
        maxWidth: maxWidthForPages,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    };

    if (companyList instanceof Error) throw companyList; 
    if (snUser instanceof Error) throw snUser; 

    const MyCompaniesComponent = !snUser || !companyList ? 
        <MyCompaniesSkeleton count={10} /> : 
        <MyCompaniesDisplay
            companies={companyList}
            userSysId={snUser.sys_id}
            nameClickFn={(comp) => navigate(`/${regionPath}/companies/${comp.u_company_id}/${comp.sys_id}`)}
            isTouchScreen={isTouchScreen()}
        />

    return (
        <Box sx={containerSx}>
            <HomePgComponent 
                header="My Companies" 
                subheader="Companies where you are the Strategic Lead, Security Lead, Account Owner or Client Success Manager" 
                body={MyCompaniesComponent}
            />
        </Box>
    );
}