import React from "react";
import { useNavigate } from "react-router-dom";

import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import Button from "@mui/material/Button";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import RecentOrdersSkeleton from "../components/Skeleton/RecentOrdersSkeleton";
import RecentOrdersDisplay from "../components/HomePgComponents/RecentOrdersDisplay";
import ErrorComponent from "../components/Error/Error";
import isTouchScreen from "../hooks/isTouchScreen";
import useRegionPath from "src/hooks/useRegionPath";
import { useCompanyList } from "src/hooks/useCompanyList";
import { useAllRecentMRROrders } from "src/hooks/useAllRecentMRROrders";

interface Props {
    showAllResults: boolean;
}

const RecentOrders = (props: Props) => {
    //get the Recent Orders data
    const recentOrders = useAllRecentMRROrders();
    const companies = useCompanyList();
    const region = useRegionPath(); 

    //setup Orders link navigation function to pass into display
    const navigate = useNavigate();
    const onClickFn = (sys_id: string, orderNumber:string) => {
        // u_company_id is cosmetic and doesn't affect navigation.  If not available, load with an underscore char in its place.
        let u_company_id = "_"; 
        if(companies && !(companies instanceof Error)){
            const company = companies.find(el => el.sys_id === sys_id); 
            u_company_id = company && company.u_company_id ? company.u_company_id : "_"; 
        }
        navigate(`/${region}/companies/${u_company_id}/${sys_id}/orders#${orderNumber}`);
    };

    //Theme settings
    const { maxWidthForPages } = useTheme();
    const containerSx: SxProps<Theme> = {
        width: "100%",
        maxWidth: maxWidthForPages,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <Box sx={containerSx}>
            <Card sx={{ width: "95%", marginY: "30px" }}>
                <CardContent>
                    <Typography
                        variant="h5"
                        sx={{
                            color: "black",
                            flexGrow: 1,
                            textAlign: "center",
                        }}
                    >
                        <LocalFireDepartmentIcon
                            sx={{
                                color: "#fa4d0a",
                                verticalAlign: "middle",
                                marginBottom: "3px",
                            }}
                        />{" "}
                        Recent Wins{" "}
                        <LocalFireDepartmentIcon
                            sx={{
                                color: "#fa4d0a",
                                verticalAlign: "middle",
                                marginBottom: "3px",
                            }}
                        />
                    </Typography>
                    {
                        recentOrders instanceof Error ? <ErrorComponent /> :
                        (recentOrders == null ? <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <RecentOrdersSkeleton
                                count={props.showAllResults ? 10 : 5}
                            />
                        </Box> : <Box>
                                        <RecentOrdersDisplay data-testid="recent-orders-display-element"
                                            recentOrders={recentOrders}
                                            showAllResults={
                                                props.showAllResults
                                            }
                                            onClickFn={onClickFn}
                                            isTouchScreen={isTouchScreen()}
                                        />
                                    </Box>)
                    }
                    {!props.showAllResults ? (
                        <Box sx={{ textAlign: "center", marginTop: 2 }}>
                            <Button
                                variant="contained"
                                onClick={() => navigate(`/${region}/recent-wins`)}
                            >
                                See More Recent Wins
                            </Button>
                        </Box>
                    ) : null}
                </CardContent>
            </Card>
        </Box>
    );
};

export default RecentOrders;
