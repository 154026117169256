import React from 'react'; 
import { Box, FormControl, InputLabel, MenuItem, Switch, Select, Stack, Typography } from '@mui/material'; 
import { grey } from '@mui/material/colors'; 
import { CatalogItem } from 'src/types';
import { useSearchParams } from 'react-router-dom';

interface SortControlProps {
    changeSort: (colName: keyof CatalogItem) => void
}

export default function SortControl({changeSort}: SortControlProps){
    const [searchParams] = useSearchParams();
    const orderedBy = searchParams.get('ordered-by')?.toLowerCase() as keyof CatalogItem; 
    const desc = Boolean(searchParams.get('desc')); 

    return (
        <Box sx={{my:3}}>
            <Stack>
                <FormControl fullWidth>
                    <InputLabel id="ordered-by-sort-label">Sort Order</InputLabel>
                    <Select
                        labelId="ordered-by-sort-label"
                        id="ordered-by-sort"
                        value={orderedBy || ''}
                        label="Sort Order"
                        onChange={(e) => changeSort(e.target.value as keyof CatalogItem)}
                    >
                        <MenuItem value='name'>Name</MenuItem>
                        <MenuItem value='owned_by'>Owned By</MenuItem>
                        <MenuItem value='u_product_family'>Product Family</MenuItem>
                        <MenuItem value='u_category'>Category</MenuItem>
                        <MenuItem value='u_sub_category'>Sub Category</MenuItem>
                        <MenuItem value='price'>Price</MenuItem>
                        <MenuItem value='u_revenue'>Revenue</MenuItem>
                        <MenuItem value='u_entitlement_quantity'>Entitlement Qty.</MenuItem>
                        <MenuItem value='u_preferred_vendor'>Preferred Vendor</MenuItem>
                        <MenuItem value='u_microsoft_skuid'>MSFT SKU</MenuItem>
                    </Select>
                </FormControl>

                <Stack direction='row' justifyContent='center' alignItems='center' sx={{my:2}}>
                    <Typography sx={desc ? {color: grey[500]} : {}}>Ascending</Typography>
                    <Switch checked={desc} onClick={() => changeSort(orderedBy)} color="default" />
                    <Typography sx={!desc ? {color: grey[500]} : {}}>Descending</Typography>

                </Stack>
            </Stack>
            
        </Box>
    )
}