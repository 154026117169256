export function makeMonthCutoff(date:Date, months:number) {
    const newDate = new Date(date); 
    newDate.setMonth(newDate.getMonth() + months);
    newDate.setDate(1); 
    newDate.setHours(0,0,0,0)
    return newDate;
}

export function getMonthName(monthInd:number):string{
    const months:{[index:number]:string} = {
        0: "Jan", 
        1: "Feb", 
        2: "Mar", 
        3: "Apr", 
        4: "May", 
        5: "Jun", 
        6: "Jul", 
        7: "Aug", 
        8: "Sep", 
        9: "Oct", 
        10: "Nov", 
        11: "Dec"
    }
    return months[monthInd]; 
}