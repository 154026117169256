import { TableContainer, Table, TableHead, TableRow, TableBody, Link, Pagination, Box, TextField, Typography, styled, Tooltip, useTheme, Backdrop, Modal, Fade, Theme, Grid, Stack, Paper, ButtonBase } from '@mui/material';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { grey } from '@mui/material/colors';
import React, { useEffect, useState, useDeferredValue } from 'react';
import { CatalogItem } from 'src/types';
import OutboundIcon from '@mui/icons-material/Outbound';
import { FilterDrawerButton } from 'src/components/AdvancedSearch/FilterResultDisplay';
import { useLocation, useSearchParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { adjustSearchTermInWindowUrl, findSearchTermInWindowUrl, with2decimals } from 'src/util/misc';

interface ProductCatalogTableProps {
    catalogItems: CatalogItem[]
    screenSizeMed: boolean
    screenSizeSm: boolean
    controlDrawer: (change?: boolean) => void
    numFiltersSelected: number
    changeSort: (colName: keyof CatalogItem) => void
    badParams: string[]
}

export default function ProductCatalogTable({ catalogItems, screenSizeSm, screenSizeMed, controlDrawer, numFiltersSelected, changeSort, badParams }: ProductCatalogTableProps) {
    const searchFromUrl = findSearchTermInWindowUrl();
    const [search, setSearch] = useState(searchFromUrl);
    const deferredSearch = useDeferredValue(search); 
    const [page, setPage] = useState(1);
    const theme = useTheme();
    useEffect(() => {
        setPage(1)
    }, [catalogItems, deferredSearch])

    const handlePage = (_e: any, page: number) => {
        setPage(page)
    }

    const passesTextSearch = catalogItems.filter(el => {
        return el.name.toLowerCase().includes(deferredSearch.toLowerCase()) ||
            el.u_product_id.toLowerCase().includes(deferredSearch.toLowerCase()) || 
            el.u_product_catalog_description.toLowerCase().includes(deferredSearch.toLowerCase()) ||
            el.u_proposal_notes.toLowerCase().includes(deferredSearch.toLowerCase())
    })

    const PAGE_SIZE = 100; 
    const maxPages = Math.ceil(passesTextSearch.length / PAGE_SIZE)
    const minInd = page * PAGE_SIZE - PAGE_SIZE;
    const maxInd = page * PAGE_SIZE;

    const recordsShown = passesTextSearch.filter((el, ind) => ind >= minInd && ind < maxInd);

    const [modalOpen, setModalOpen] = React.useState(false);
    const [catalogItem, setCatalogItem] = useState<CatalogItem | null>(null)
    const handleOpen = (item: CatalogItem) => { setCatalogItem(item); setModalOpen(true); }
    const handleClose = () => setModalOpen(false);

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: screenSizeSm ? 250 : 400,
        bgcolor: 'background.paper',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 1,
        boxShadow: 24,
        p: 3,
    };
    const badSearchParamsStr = badParams.map((el, ind) => ind === 0 ? el : `, ${el}`).join(""); 

    // handling search logic within the results component to avoid unneccessary filter section rerenders

    // searchTerm= is not a part of react-router as are other search params.  
    // searchTerm= is updated in URL using window history, without a router state change
    // search bar maintains its own state, unlike the other filters which derive state from react-router vals  
    // searchTerm= in the URL drives state only when URL is reloaded or shared when it is passed as the default value to useState above
    const updateSearch = (newVal: string) => {
        setSearch(newVal);
        adjustSearchTermInWindowUrl(newVal);
    }

    const location = useLocation();
    useEffect(() => {
        // reset in Product Catalog navigates to searchTerm= with no val if it needs to be cleared.  Mechanism to clear state here
        if(!findSearchTermInWindowUrl()) setSearch("")
    }, [location.search, location.pathname])

    return (
        badParams.length ? 
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{alignSelf: 'start', marginLeft: 2, my:5, py:1}}>
                {screenSizeMed && <FilterDrawerButton onClick={() => controlDrawer(true)} />}
            </Box>
            <Box sx={{textAlign: 'center', p:5, fontSize: 'large'}}>
                Bad search URL.  The following search params may no longer exist: {badSearchParamsStr}.  Reset and try again.
            </Box>
        </Box> : 
        <>
            <Box sx={{ padding: 2 }}>
                <Box sx={{ py: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {!screenSizeMed && <Typography variant="h5">Product Catalog</Typography>}
                    {screenSizeMed && <FilterDrawerButton onClick={() => controlDrawer(true)} />}
                    <Box>
                        <Typography variant="h6" sx={{ color: grey[500] }}>
                            {passesTextSearch.length} Results
                        </Typography>
                        {screenSizeMed && (
                            <Typography variant="h6" sx={{ color: grey[600], fontSize: 15 }}>
                                {numFiltersSelected} {numFiltersSelected === 1 ? "Filter" : "Filters"}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <TextField
                    hiddenLabel
                    fullWidth
                    id="filled-hidden-label-normal"
                    placeholder={!search.length ? "Search for product name, ID, description or notes within filtered results..." : undefined}
                    value={search}
                    variant="outlined"
                    size="small"
                    onChange={(e) => updateSearch(e.target.value)}
                // disabled={props.isLoading}
                />
            </Box>
            <Box sx={{ padding: 2 }}>

                {
                    recordsShown.length ? 
                        <Box sx={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <Pagination sx={{ my: 2 }} count={maxPages} page={page} siblingCount={1} onChange={handlePage} />
                        </Box> : 
                        <></>
                }

                {
                    !recordsShown.length ? 
                        <Box sx={{textAlign: 'center', p:5, fontSize: 'large'}}>
                            No results returned from this search.  Update your filters or search term.
                        </Box> :
                        screenSizeSm
                            ? <SmallScreenResults
                                recordsShown={recordsShown}
                                search={deferredSearch}
                                theme={theme}
                                handleOpen={handleOpen} />
                            : <FullScreenResults
                                recordsShown={recordsShown}
                                search={deferredSearch}
                                handleOpen={handleOpen}
                                theme={theme}
                                changeSort={changeSort}
                            />
                }

                <Box sx={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "end" }}>
                    <Pagination sx={{ mt: 2 }} count={maxPages} page={page} siblingCount={1} onChange={handlePage} />
                </Box>
            </Box>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalOpen}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                >
                    <Fade in={modalOpen}>
                        <Box sx={modalStyle}>
                            {!catalogItem ? <></> :
                                <SmallScreenResultOrModalBody record={catalogItem} theme={theme} />
                            }
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>

    )
}

interface FullScreenResultsProps {
    recordsShown: CatalogItem[],
    search: string,
    handleOpen: (item: CatalogItem) => void,
    theme: Theme,
    changeSort: (colName: keyof CatalogItem) => void
}


function FullScreenResults({ recordsShown, search, handleOpen, theme, changeSort }: FullScreenResultsProps) {
    return (
        <TableContainer>
            <Table size="small">
                <TableHeader changeSort={changeSort} />
                <TableBody>
                    {recordsShown.map((item) => {
                        return (
                            <ButtonBase
                                key={item.sys_id}
                                component={TableRow}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    display: "table-row",
                                    ":hover": {
                                        backgroundColor: "#f5f5f5",
                                        cursor: "pointer",
                                    }, 
                                    height: '53px'
                                }}
                                onClick={(_e) => handleOpen(item)}
                            >
                                <TableCell component="th" scope="row" sx={{px: 1}} >
                                    <Tooltip title={item.name} placement='top'>
                                        <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '250px', cursor: 'pointer' }}>
                                            <HighlightSearchInName search={search} name={item.name} theme={theme} screenSizeSm={false} />
                                        </Box>
                                    </Tooltip>
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{px: 1, whiteSpace: 'nowrap'}} >
                                    <HighlightSearchInName search={search} name={item.u_product_id} theme={theme} screenSizeSm={false} />
                                </TableCell>
                                <TableCell align="right" sx={{px: 1}} >{item.owned_by.display}</TableCell>
                                <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}} >{item.u_product_family}</TableCell>
                                <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>{item.u_category}</TableCell>
                                <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>{item.u_sub_category}</TableCell>
                                <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>$ {with2decimals(item.price)}</TableCell>
                                <TableCell sx={{px: 1,  whiteSpace: 'nowrap' }} align="right" >{item.u_entitlement_quantity}</TableCell>
                                <TableCell align="right" sx={{px: 1,  whiteSpace: 'nowrap' }}>{item.u_preferred_vendor}</TableCell>
                                <TableCell align="right" sx={{px: 1,  whiteSpace: 'nowrap' }}>{item.u_revenue}</TableCell>
                                <TableCell align="right" sx={{px: 1}} onClick={(_e) => { _e.stopPropagation() }}> <Link href={item.u_salesforce_link} target="_blank"><OutboundIcon /></Link> </TableCell>
                            </ButtonBase>
                        )
                    })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )

}

interface HighlightSearchInNameProps {
    search?: string
    name: string
    theme: Theme,
    screenSizeSm: boolean
}

function HighlightSearchInName({ search, name, theme }: HighlightSearchInNameProps) {
    let escapedSearch = search;
    // these chars were causing an error when entered into regex un-escaped.
    const charsToEscape = ["(", ")", "\\", ".", "{", "}", "?", "*"];
    charsToEscape.forEach(char => {
        escapedSearch = escapedSearch?.replace(char, `\\${char}`)
    })
    const searchRegExp = new RegExp(`(${escapedSearch})`, 'gi');

    const nameIncludesSearch = name.toLowerCase().includes(search?.toLowerCase() || '');
    const LIMIT_AT_LENGTH = 30;
    const needsLimiting = nameIncludesSearch && LIMIT_AT_LENGTH < name.length;

    return (
        !search ? <>{name}</> :
            <>
                {name.split(searchRegExp).map((segment, ind) => {
                    const segmentMatchSearch = segment.toLowerCase() === search.toLowerCase();
                    return (
                        <Box
                            key={`search-result-segment-${ind}`}
                            component="span"
                            color={segmentMatchSearch ? theme.palette.warning.main : ''}
                        >
                            {segmentMatchSearch ?
                                segment :
                                needsLimiting && segment.length && segment.length > 25 ? `${segment.slice(0, 25)}...` : segment
                            }
                        </Box>
                    )
                })
                }
            </>
    )
}

interface SmallScreenResultsProps {
    recordsShown: CatalogItem[],
    handleOpen: (item: CatalogItem) => void,
    search?: string
    theme: Theme
}

function SmallScreenResults({ recordsShown, search, theme, handleOpen }: SmallScreenResultsProps) {
    return (
        <Box sx={{ p: 1 }}>
            {
                recordsShown.map((el) => {
                    return (
                        <Paper sx={{ p: 1, my: 1, cursor: 'pointer' }} key={el.sys_id} onClick={() => handleOpen(el)}>
                            <SmallScreenResultOrModalBody record={el} search={search} theme={theme} />
                        </Paper>
                    )
                })
            }
        </Box>
    )
}

interface SmallScreenResultOrModalBodyProps {
    record: CatalogItem
    search?: string
    theme: Theme
}

function SmallScreenResultOrModalBody({ record, search, theme }: SmallScreenResultOrModalBodyProps) {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <GridContent header="Product Name">
                    <HighlightSearchInName search={search} name={record.name} theme={theme} screenSizeSm={true} />
                </GridContent>
            </Grid>
            <Grid item xs={6}>
                <GridContent header="Product ID">
                    <HighlightSearchInName search={search} name={record.u_product_id} theme={theme} screenSizeSm={true} />
                </GridContent>
            </Grid>
            <Grid item xs={6}>
                <GridContent header="Owned By" body={record.owned_by.display} />
            </Grid>
            <Grid item xs={6}>
                <GridContent header="Product Family" body={record.u_product_family} />
            </Grid>

            <Grid item xs={6}>
                <GridContent header="Category" body={record.u_category} />
            </Grid>
            <Grid item xs={6}>
                <GridContent header="SubCategory" body={record.u_sub_category} />
            </Grid>

            <Grid item xs={6}>
                <GridContent header="Price" body={`$ ${with2decimals(record.price)}`} />
            </Grid>
            <Grid item xs={6}>
                <GridContent header="Ent. Qty" body={record.u_entitlement_quantity} />
            </Grid>
            <Grid item xs={6}>
                <GridContent header="Pr. Vendor" body={record.u_preferred_vendor} />
            </Grid>
            <Grid item xs={6}>
                <GridContent header="Revenue Type" body={record.u_revenue} />
            </Grid>
            <Grid item xs={6}>
                <GridContent header="SF Link" body={<Link href={record.u_salesforce_link} target="_blank"><OutboundIcon /></Link>} />
            </Grid>
            <Grid item xs={12}>
                <GridContent header="Description" >
                    <Box sx={{ whiteSpace: 'pre-line' }}>
                        {record.u_product_catalog_description}
                    </Box>
                </GridContent>
            </Grid>
            <Grid item xs={12}>
                <GridContent header="Notes">
                    <Box sx={{ whiteSpace: 'pre-line' }}>
                        {record.u_proposal_notes}
                    </Box>
                </GridContent>
            </Grid>
        </Grid>
    )

}


interface GridContentsProps {
    header?: string
    body: string | React.ReactNode
}

interface GridContentsChildLikeProps {
    header?: string;
    children: string | React.ReactNode;
}

export function GridContent({ header, children }: GridContentsChildLikeProps): JSX.Element;
export function GridContent({ header, body }: GridContentsProps): JSX.Element;
export function GridContent(props: GridContentsProps | GridContentsChildLikeProps) {
    const { header } = props;
    const body = 'body' in props ? props.body : props.children;

    return (
        <Stack>
            {header ? <Box sx={{ color: grey[500] }}>{header}</Box> : <></>}
            <Box sx={{ fontSize: '1.4rem' }}>{body}</Box>
        </Stack>
    )
}




const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    }
}));

interface TableHeaderProps {
    changeSort: (colName: keyof CatalogItem) => void
}

export function TableHeader({ changeSort }: TableHeaderProps) {
    const [searchParams] = useSearchParams();
    const orderedBy = searchParams.get('ordered-by')?.toLowerCase() as keyof CatalogItem;
    const desc = searchParams.get('desc');

    return (
        <TableHead >
            <TableRow>
                <StyledHeaderCell sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('name')}>
                    <Stack direction='row' sx={{ cursor: 'pointer' }}>
                        <Box>Product Name</Box>
                        <SortIcon col={'name'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('u_product_id')}>
                    <Stack direction='row' sx={{ cursor: 'pointer' }}>
                        <Box>Product ID</Box>
                        <SortIcon col={'u_product_id'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('owned_by')}>
                    <Stack direction='row' justifyContent='center' sx={{ cursor: 'pointer', width: '100%' }}>
                        <Box>Owner</Box>
                        <SortIcon col={'owned_by'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('u_product_family')}>
                    <Stack direction='row' justifyContent='center' sx={{ cursor: 'pointer', width: '100%' }}>
                        <Box>Prod. Family</Box>
                        <SortIcon col={'u_product_family'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('u_category')}>
                    <Stack direction='row' justifyContent='center' sx={{ cursor: 'pointer', width: '100%' }}>
                        <Box>Category</Box>
                        <SortIcon col={'u_category'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('u_sub_category')}>
                    <Stack direction='row' justifyContent='center' sx={{ cursor: 'pointer',  width: '100%' }}>
                        <Box>Sub-Category</Box>
                        <SortIcon col={'u_sub_category'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('price')}>
                    <Stack direction='row' justifyContent='center' sx={{ cursor: 'pointer', width: '100%' }}>
                        <Box>Price</Box>
                        <SortIcon col={'price'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('u_entitlement_quantity')}>
                    <Stack direction='row' justifyContent='center' sx={{ cursor: 'pointer', width: '100%' }}>
                        <Box>Ent. Qty</Box>
                        <SortIcon col={'u_entitlement_quantity'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('u_preferred_vendor')}>
                    <Stack direction='row' justifyContent='center' sx={{ cursor: 'pointer', width: '100%' }}>
                        <Box>Pr. Vendor</Box>
                        <SortIcon col={'u_preferred_vendor'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap' }} onClick={() => changeSort('u_revenue')}>
                    <Stack direction='row' justifyContent='center' sx={{ cursor: 'pointer', width: '100%' }}>
                        <Box>Revenue Type</Box>
                        <SortIcon col={'u_revenue'} orderByCol={orderedBy} desc={Boolean(desc)} />
                    </Stack>
                </StyledHeaderCell>
                <StyledHeaderCell align="right" sx={{ whiteSpace: 'nowrap'}}>SF Link</StyledHeaderCell>
            </TableRow>
        </TableHead>
    )
}

interface SortIconProps {
    col: keyof CatalogItem
    orderByCol: keyof CatalogItem,
    desc: boolean
}

function SortIcon({ col, orderByCol, desc }: SortIconProps) {
    if (col === orderByCol) {
        return desc ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
    }
    return <></>
}