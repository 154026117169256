import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { useNotCoveredTask } from "src/hooks/useNotCoveredTasks"
import useLocationElementExtractor from "src/pages/Companies/hooks/useLocationElementExtractor";
import {  Cell, XAxis, YAxis, Tooltip, Scatter, ScatterChart, ZAxis, TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';


export default function NotCoveredTasks() {
    const { currentValue: companySysId } = useLocationElementExtractor("sys_id");
    const _today = new Date();
    const _TwoWeeksAgo = new Date(_today.getTime() - (13 * 24 * 60 * 60 * 1000));
    // const today = new Date(_today.getFullYear(), _today.getMonth(), _today.getDate(), 23, 59, 59, 999);
    // const TwoWeeksAgo = new Date(_TwoWeeksAgo.getFullYear(), _TwoWeeksAgo.getMonth(), _TwoWeeksAgo.getDate());
    const YYYYMMDD = `${_today.getFullYear()}-${_today.getMonth() + 1}-${_today.getDate()}`
    const YYYYMMDD2WeeksAgo = `${_TwoWeeksAgo.getFullYear()}-${_TwoWeeksAgo.getMonth() + 1}-${_TwoWeeksAgo.getDate()}`;

    const notCoveredTasks = useNotCoveredTask(companySysId, YYYYMMDD2WeeksAgo, YYYYMMDD);

    if(notCoveredTasks instanceof Error) throw notCoveredTasks;
    if(!notCoveredTasks) return (
        <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Skeleton width={150} height={30}/>
            <Skeleton width={250} height={40}/>
        </Box>
    )

    let totalTaskTime = 0; 
    notCoveredTasks.forEach(el => totalTaskTime += parseInt(el.time_in_seconds));
    const totalTaskTimeHours = totalTaskTime / 3600;

    const chartDataLu: Record<string, number> = {};
    let date = _TwoWeeksAgo; 
    while(date <= _today){
        const dateStr = `${date.getMonth() +1}/${date.getDate()}/${date.getFullYear()}`; 
        chartDataLu[dateStr] = 0;
        date = new Date(date.getTime() + (24 * 60 * 60 * 1000));
    }
    notCoveredTasks.forEach(el => {
        const dateStr = el.u_time_start.split(" ")[0];
        const year = dateStr.split("-")[0];
        const month = `${parseInt(dateStr.split("-")[1]).toString()}`; 
        const day = `${parseInt(dateStr.split("-")[2]).toString()}`; 
        const modifiedDateStr = `${month}/${day}/${year}`;
        chartDataLu[modifiedDateStr] += parseInt(el.time_in_seconds) / 3600;
    });
    const chartData = Object.keys(chartDataLu).map(key => ({date: key, hours: chartDataLu[key], y:1}));

    return (
        <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Box sx={{my:1}}><Typography>Non-Covered Work: <b>{Math.round(totalTaskTimeHours*10)/10} hours</b></Typography></Box>
            {
                totalTaskTimeHours > 0 && <NotCoveredTaskChart chartData={chartData}/>
            }
        </Box>
    )

}

interface NotCoveredTaskChartProps {
    chartData: {date: string, hours: number, y:number}[]
}

function NotCoveredTaskChart({chartData}: NotCoveredTaskChartProps){
    const theme = useTheme(); 

    const maxHours = Math.max(...chartData.map(el => el.hours));
    const withOpacity = chartData.map(el => {
        return {...el, opacity: Math.max(el.hours / maxHours, el.hours > 0 ? .4 : .17)}
    });
    
        
    return (
        // <Box  height={50} width={300}>
            <ScatterChart data={withOpacity} margin={{left: 15, right: 15, top: 10}} height={50} width={300}>
                <XAxis dataKey="date" axisLine={false} interval={12} />
                <YAxis dataKey="y" type="number" hide={true} />
                <ZAxis type="number" dataKey="y" range={[325,325]} domain={[700,700]} />
                <Scatter shape="square" data={chartData}>
                    {
                        withOpacity.map((el, i) => <Cell key={i} opacity={el.hours > 0 ? el.opacity : 1} fill={el.hours > 0 ? theme.palette.error.light : theme.palette.grey[300]}/>)
                    }
                </Scatter>
                <Tooltip cursor={false} content={<CustomTooltip />}/>
            </ScatterChart>
        // </Box>
   );
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    const theme = useTheme(); 
    if (active && payload && payload.length) {
        const info = payload[0].payload; 
        const hours = Math.round(info.hours*100)/100; 
        const content = (
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', backgroundColor: 'white', padding: .5, borderRadius:1, border:1}}>
                <Typography sx={{fontSize: 10, color: theme.palette.grey[700]}}>{info.date}</Typography>
                <Typography>{hours} hour{hours===1 ? "" : "s"}</Typography>
            </Box>
        )
      return (
        <div className="custom-tooltip">
            {content}
        </div>
      );
    }
  
    return null;
  };