import { keyframes } from '@emotion/react';
import { Box, alpha, styled } from '@mui/material'; 

const ellipseAnimation = keyframes`
    to {
        transform: scale(1.3);
    }
`; 


const ElipsesAnimation1 = styled('div')({
    animation: `${ellipseAnimation} linear 900ms 0ms infinite`,
    padding: '1px'
});
const ElipsesAnimation2 = styled('div')({
    animation: `${ellipseAnimation} linear 900ms 200ms infinite`,
    padding: '1px'
});
const ElipsesAnimation3 = styled('div')({
    animation: `${ellipseAnimation} linear 900ms 400ms infinite`,
    padding: '1px'
});

const StyledBox = styled(Box)`
    display: flex;
    flex-direction: 'row'; 
    align-items: center;
    padding: 0 1em;
    flex-grow: 1;
    max-width: unset;
    color: ${props => alpha(props.theme.palette.text.primary, 0.54)};
`;

const LoadingAnimation = () => {
    return (
        <StyledBox >
            <Box sx={{paddingRight: 0.5}}>Loading</Box>
            <ElipsesAnimation1>.</ElipsesAnimation1>
            <ElipsesAnimation2>.</ElipsesAnimation2>
            <ElipsesAnimation3>.</ElipsesAnimation3>
        </StyledBox>
    )
}
export default LoadingAnimation; 