import { useQuery } from "@tanstack/react-query";
import { backend as backendForApiKey } from "src/services/backend";
import { CompanyLocations, CompanyLocationsIntermediate, DataCenterLocation, DataCenterLocationsIntermediate } from "src/types";
import { useBackend, useRegion } from "./useRegion";


export function useMapsApiKey(): (string | null | Error) {

    const { status, data, error } = useQuery<string, Error>({
        queryFn: () => backendForApiKey.get<string>(`/maps/api-key`).then((res) => res.data),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["maps-api-key"],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}



export function useLocations(companyId: string): CompanyLocationsIntermediate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<CompanyLocations[], Error>({
        queryFn: () => backend.get<{ result: CompanyLocations[] }>(`/getLocations/${companyId}`)
            .then((res) => res.data.result)
            .then(data => data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["locations", companyId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}

export function useDataCenterLocations(companyId: string): DataCenterLocationsIntermediate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<DataCenterLocation[], Error>({
        queryFn: () => backend.get<{ result: DataCenterLocation[] }>(`/getDataCenters/${companyId}`)
            .then((res) => res.data.result)
            .then(data => data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["data-center-locations", companyId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}

