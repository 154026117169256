import Box from '@mui/material/Box'; 
import Stack from '@mui/material/Stack'; 
import { grey } from '@mui/material/colors'; 
import FlashingDiv from './FlashingDiv';

export type InvoiceOrderSkeletonProps = {
    count: number
}


export default function InvoiceOrderSkeleton(props: InvoiceOrderSkeletonProps){
    const count = props.count; 
    //@ts-ignore
    const listOfSkeletons = Array(count).fill().map((x, index) => <IndSkeleton key={String(index)}/>)
    return (
        <>{listOfSkeletons}</>
    )
}

function IndSkeleton() {

    return (
        <Box sx={{marginTop: 1}}>
            <Stack sx={{width: '100%', borderBottom: 1, borderColor: 'lightgray'}}>
                <FlashingDiv sx={{fontSize: '1.2rem', backgroundColor: grey[200], width: '6rem', borderRadius: 1, marginBottom: 1}}>
                    &nbsp;
                </FlashingDiv>
                <Stack direction="row" alignItems="center" sx={{width: '100%', marginBottom: 1}}>
                    <FlashingDiv sx={{fontSize: '1.8rem', width: '12rem', backgroundColor: grey[200], borderRadius: 1}}>
                        &nbsp;
                    </FlashingDiv>
                    <FlashingDiv sx={{marginLeft: '8px', marginRight: "4px", fontSize: "1.4rem", flexShrink: 2, width: '4rem', overflow: 'hidden', borderRadius: 4}}>&nbsp;</FlashingDiv>
                    {/** Empty div with flexGrow to fill blank space */}
                    <Box sx={{flexGrow: 2}}></Box>
                    <FlashingDiv sx={{ fontSize: '1.8rem', fontWeight: 'bold', backgroundColor: grey[200], borderRadius: 1, width: '8rem'}}>
                        &nbsp;
                    </FlashingDiv>
                </Stack>
            </Stack>
        </Box>
    )   
}