import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";

const { 
    REACT_APP_CLIENT_ID, 
    REACT_APP_AUTHORITY, 
    REACT_APP_REDIRECT_URI,
    REACT_APP_AZURE_SCOPES
} = process.env;



const pca = new PublicClientApplication({
    auth: {
        clientId: REACT_APP_CLIENT_ID!,
        authority: REACT_APP_AUTHORITY!,
        redirectUri: REACT_APP_REDIRECT_URI!,
    },
});

// Setting Active Account when user is successfully logged in
pca.addEventCallback((event) => {
    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const account = pca.getActiveAccount();
        pca.acquireTokenSilent({
            account: account!,
            scopes: REACT_APP_AZURE_SCOPES!.split(",").map(s => s.trim())
        });
    }
    
    if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event.payload) pca.setActiveAccount(event.payload as AccountInfo);
    }
});



export default pca;