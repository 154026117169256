import { useQuery } from "@tanstack/react-query";
import { Invoice, InvoiceDetails, InvoicesIndeterminate, InvoicesDetailsIndeterminate } from "src/types";
import { useBackend, useRegion } from "./useRegion";

export function useInvoices(companyId: string): InvoicesIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<Invoice[], Error>({
        queryFn: () => backend.get<{ result: Invoice[] }>(`/getInvoices/${companyId}`).then((res) => res.data.result),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["invoices", companyId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}

export function useInvoiceDetails(invoiceId: string): InvoicesDetailsIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<InvoiceDetails[], Error>({
        queryFn: () => backend.get<{ result: InvoiceDetails[] }>(`/getInvoiceDetails/${invoiceId}`).then((res) => res.data.result),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["invoices-details", invoiceId, region],
        staleTime: Infinity,
        enabled: !!invoiceId,
    });
    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}