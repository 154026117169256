import CloudIcon from '@mui/icons-material/Cloud';
import LockIcon from '@mui/icons-material/Lock';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BackupIcon from '@mui/icons-material/Backup';
import FloodIcon from '@mui/icons-material/Flood';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import StorageIcon from '@mui/icons-material/Storage';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";


import { EntitlementWProductInfo } from "src/types";
import { Backdrop, Box, ButtonBase, Chip, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

type categories = "Advisory Services" | "Backup" | "Cloud" | "Compliance" | "Disaster Recovery" | "End User Support" | 
    "IT Automation" | "Managed Infrastructure" | "Microsoft 365" | "Security"

type categoriesPlusLegacy = categories | 'Legacy Product'

const iconLookup: Record<categories, React.ReactElement> = { 
    "Advisory Services": <ManageAccountsIcon fontSize="large" />,
    "Backup": <BackupIcon fontSize="large" />, 
    "Cloud": <CloudIcon fontSize="large" />,
    "Compliance": <AssuredWorkloadIcon fontSize="large" /> ,
    "Disaster Recovery": <FloodIcon fontSize="large" /> ,
    "End User Support": <SupportAgentIcon fontSize="large" />,
    "IT Automation": <PrecisionManufacturingIcon fontSize="large" />,
    "Managed Infrastructure": <StorageIcon fontSize="large" />,
    "Microsoft 365": <MicrosoftIcon fontSize="large" /> ,
    "Security": <LockIcon fontSize="large" />
}



interface ServicesDisplayProps {
    services: EntitlementWProductInfo[],
}

export default function ServicesDisplay({ services }: ServicesDisplayProps) {

    services = services.sort((a,b) => a.u_product_id < b.u_product_id ? -1 : 1)
    const isScreenSmall = useMediaQuery(useTheme().breakpoints.down("sm"));

    const [selectedCat, setSelectedCat] = useState<categoriesPlusLegacy | null>(null); 
    const [showingLegacy, setShowingLegacy] = useState<boolean>(false); 
    const selectedCategoryEnts = !selectedCat ? [] : services.filter(el => el.u_product_family && el.u_product_family.toLowerCase() === selectedCat.toLowerCase()); 

    const legacyOfferings = services.filter(el => el.u_product_family && el.u_product_family.toLowerCase() === "legacy product");
    const stackPadding = showingLegacy ? 1 : 0; 
    const modalWidth = isScreenSmall ? '200px' : '400px';
    const modalHeight = isScreenSmall ? '120px' : '80px'; 

    return(
        <Stack>
            {
                !legacyOfferings.length ? <></> : 
                    <LegacyProductSwitch legacyOfferings={legacyOfferings} setSelectedCategory={setSelectedCat} 
                        showingLegacy={showingLegacy} setShowingLegacy={setShowingLegacy}/>
            }
            <Box sx={{position: 'relative'}}>
                <Backdrop sx={{position: 'absolute', backgroundColor: '#000A'}} open={showingLegacy} />
                {!showingLegacy ? <></> : 
                    <Box sx={{position: "absolute", zIndex: 1, left: 0, right: 0, 
                        marginLeft: 'auto', marginRight: 'auto', width: modalWidth, backgroundColor: "white", color: 'black',
                        padding: 2, top: 0, bottom: 0, marginTop: 'auto', marginBottom: 'auto', height: modalHeight}}>
                        <Typography sx={{textAlign: 'center', width: "100%"}}>
                            Legacy products displayed below.  
                        </Typography>
                        <Typography sx={{textAlign: 'center', width: "100%", fontSize: 10, mt:2}}>
                            Deselect legacy toggle to access product families.
                        </Typography>
                    </Box>
                }
                <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'stretch', spacing: '1', paddingX: stackPadding }} >
                    {
                        Object.keys(iconLookup).map(key => 
                            <CategoryBox entitlements={services} category={key as categories} setSelectedCategory={setSelectedCat} selectedCat={selectedCat} isScreenSmall={isScreenSmall}
                                key={`category-box-${key}`}/> )
                    }
                </Box>                
            </Box>

            {
                isScreenSmall ? 
                    <EntitlementTableSm entitlements={selectedCategoryEnts} setSelectedCategory={setSelectedCat} showingLegacy={showingLegacy}/> : 
                    <EntitlementTable entitlements={selectedCategoryEnts} setSelectedCategory={setSelectedCat} showingLegacy={showingLegacy}/>
            }

        </Stack>

    )

}

interface LegacyProductSwitchProps {
    legacyOfferings: EntitlementWProductInfo[], 
    setSelectedCategory: (newCat:categoriesPlusLegacy | null) => void, 
    setShowingLegacy: (newVal: boolean) => void
    showingLegacy: boolean
}

function LegacyProductSwitch({legacyOfferings, setSelectedCategory, showingLegacy, setShowingLegacy}:LegacyProductSwitchProps){

    const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        setShowingLegacy(value)
        if(value) { 
            setSelectedCategory("Legacy Product")
        } else {
            setSelectedCategory(null)
        }
    }

    const message = `Show ${legacyOfferings.length} Legacy Product${legacyOfferings.length > 1 ? 's': ''}`; 

    return(
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', fontSize: 12, me:1}}>
            <Switch checked={showingLegacy} onChange={handleChange} />
            {message}
        </Box>
    )
}



interface CategoryBoxProps {
    entitlements: EntitlementWProductInfo[], 
    category: categories, 
    setSelectedCategory: (newCat:categories) => void, 
    selectedCat: categoriesPlusLegacy | null, 
    isScreenSmall: boolean
}

function CategoryBox({entitlements, category, setSelectedCategory, selectedCat, isScreenSmall}: CategoryBoxProps){
//    const theme = useTheme(); 
    const categoriesEnts = entitlements.filter(el => el.u_product_family && el.u_product_family.toLowerCase() === category.toLowerCase()); 

    const addBgColor = // selectedCat === 'Legacy Product' ? {backgroundColor: theme.palette.warning.light} :
        !categoriesEnts.length ? {backgroundColor: "grey"} : 
        selectedCat === category ? {backgroundColor: "#073F5F", cursor: 'pointer'} : {backgroundColor: "#0F82C6", cursor: 'pointer'}
    
    const fontSizeSx = isScreenSmall ? {fontSize: '.95rem'} : {fontSize: '1.4rem'}
    const handleClick = () => {
        if(selectedCat === "Legacy Product") return; 
        setSelectedCategory(category)
    }

    const categoryBox = (
        <Box sx={{height: '140px', width: '100%', color: 'white', ...addBgColor, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}
            onClick={handleClick} 
            >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', pt:4}}>
                <Box sx={{px:.5}}>{iconLookup[category]}</Box>
                <Box sx={{px: '3px', textAlign: 'center', userSelect: 'none', ...fontSizeSx}}>
                    {category}
                </Box>
            </Box>
            <Box sx={{px: .5, alignSelf: 'end', minHeight: '20px'}}>
                {categoriesEnts.length ? categoriesEnts.length : ""}
            </Box>
        </Box>
    )

    return (
        selectedCat === 'Legacy Product' || !categoriesEnts.length ? 
            <Box sx={{flexGrow: 1, flexBasis: '18%', margin:"2px"}}>
                {categoryBox}
            </Box> : 
            <ButtonBase sx={{flexGrow: 1, flexBasis: '18%', margin:"2px"}}>
                {categoryBox}
            </ButtonBase>  
    )
}

const StyledIconButton = styled(IconButton)(() => ({
    p: 0,
    color: "white",
    marginLeft: "auto",
    "&:hover": {
        color: "red",
    },
}));

interface EntitlementTableProps {
    entitlements: EntitlementWProductInfo[], 
    setSelectedCategory: (newCat:categories | null) => void, 
    showingLegacy: boolean
}

function EntitlementTable({entitlements, setSelectedCategory, showingLegacy}:EntitlementTableProps){
    if(!entitlements.length) return <></>

    return(
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box alignSelf="end">
                {
                    showingLegacy ? <></> : 
                        <StyledIconButton color="inherit" onClick={() => setSelectedCategory(null) }>
                            <CloseIcon />
                        </StyledIconButton>
                }
            </Box>
            <TableContainer sx={{py:3, px:1}}>
                <Table size="small" sx={{minWidth: '1000px'}}>
                    <TableHead>
                        <TableRow  sx={{verticalAlign: 'top'}}>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Subcategory</TableCell>
                            <TableCell>Catalog Status</TableCell>
                            <TableCell>Quoting Status</TableCell>
                            <TableCell>Catalog Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            entitlements.map(el => {
                                const highlightCatStatus = el.u_catalog_status.toLowerCase() !== "active";  
                                return (
                                    <TableRow key={`${el.sys_id}-entitlement-row`}>
                                        <TableCell>{el.u_product_id}</TableCell>
                                        <TableCell>{el.name}</TableCell>
                                        <TableCell>{el.u_category}</TableCell>
                                        <TableCell>{el.u_sub_category}</TableCell>
                                        <TableCell>
                                            {highlightCatStatus ? 
                                                <Chip label={el.u_catalog_status} color="error" /> : 
                                                <>{el.u_catalog_status}</>
                                            }
                                        </TableCell>
                                        <TableCell sx={{textTransform: 'capitalize'}}>{el.u_quoting_status}</TableCell>
                                        <TableCell>{el.u_product_catalog_description}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        
    )
}

function EntitlementTableSm({entitlements, setSelectedCategory, showingLegacy}:EntitlementTableProps){
    if(!entitlements.length) return <></>
    return(
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box alignSelf="end">
                {
                    showingLegacy ? <></> : 
                        <StyledIconButton color="inherit" onClick={() => setSelectedCategory(null)}>
                            <CloseIcon />
                        </StyledIconButton>
                }
            </Box>
            {
                entitlements.map(el => {
                    const highlightCatStatus = el.u_catalog_status.toLowerCase() !== "active";  
                    return (

                        <Box sx={{borderBottom: 1, py:2, borderColor: 'divider', display: 'flex', flexDirection: 'column'}} key={`${el.sys_id}-entitlement-row`}>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <Box sx={{minWidth: '160px'}}>ID</Box>
                                <Box>{el.u_product_id}</Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <Box sx={{minWidth: '160px'}}>Name</Box>
                                <Box>{el.name}</Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <Box sx={{minWidth: '160px'}}>Category</Box>
                                <Box>{el.u_category}</Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <Box sx={{minWidth: '160px'}}>Subcategory</Box>
                                <Box>{el.u_sub_category}</Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <Box sx={{minWidth: '160px'}}>Catalog Status</Box>
                                <Box>
                                    {highlightCatStatus ? 
                                        <Chip label={el.u_catalog_status} color="error" sx={{my:.5, fontSize: '10px'}} size='small'/> :
                                        el.u_catalog_status 
                                    }
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <Box sx={{minWidth: '160px'}}>Quoting Status</Box>
                                <Box sx={{textTransform: 'capitalize'}}>{el.u_quoting_status}</Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <Box sx={{minWidth: '160px'}}>Catalog Description</Box>
                                <Box>{el.u_product_catalog_description}</Box>
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
        
    )

}
