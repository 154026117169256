import Box from '@mui/material/Box'; 
import Card from "@mui/material/Card"; 
import CardContent from '@mui/material/CardContent';
import type { SxProps, Theme } from '@mui/material';
import { Children } from 'react'; 


// css from:  https://www.youtube.com/watch?v=3yfswsnD2sw

interface ScrollerProps {
    children?: React.ReactElement<ScrollerElmProps> | React.ReactElement<ScrollerElmProps>[], 
    containerSize: number, 
    elementMinWidth: number
}

/**
 * @description Scroller component displays ScrollerElms in horizontal scroll if ScrollerElms + padding > containerSize
 * @param containerSize size of the containing div
 * @param elementMinWidth min size of ScrollerElm
 */

export function Scroller({children, containerSize, elementMinWidth, ...props}:ScrollerProps){

    const childCt = Children.count(children); 
    const padPx = 10; 

    // determine element width by subtracting padding. 2 outside pads + 1 pad for each space between the children
    const elmWidthBeforeAdjust = (containerSize - (padPx*2 + padPx*(childCt-1))) / childCt; 

    // use minWidth if needed
    let elmWidth = elmWidthBeforeAdjust < elementMinWidth ? elementMinWidth : elmWidthBeforeAdjust; 

    // if this results in container size landing on a gap, add length to the elements 
    // first gap is 0 through the padPx
    const gaps:[number,number][] = [[0, padPx]]; 
    // add gap at each pad, excluding the last one
    for(let i=1; i<=childCt-1; i++){
        let lastGap = gaps[i-1]; 
        gaps.push([lastGap[1] + elmWidth, lastGap[1] + elmWidth + padPx])
    }
    // check if containerSize is within gaps, add to element width if so
    gaps.forEach(gap => {
        if(gap[0]<containerSize && gap[1] > containerSize) {
            elmWidth += 15; 
        }
    })


    return (
        <Box 
            sx={{
                display: 'grid', 
                gridAutoFlow: 'column', 
                gridAutoColumns: `${elmWidth}px`,  
                gap: `${padPx}px`,
                overflowX: 'auto', 
                overscrollBehaviorInline: 'contain', 
                padding: `0 ${padPx}px 15px`, 
                scrollSnapType: 'inline mandatory', 
                scrollPaddingInline: `${padPx-2}px`, 
                '&::-webkit-scrollbar': {
                    display: 'none'
                }
            }
        }>
            {children}
        </Box>
    )
}


interface ScrollerElmProps {
    children?: React.ReactNode, 
    cardSx?: SxProps<Theme>,
    cardContentSx?: SxProps<Theme>
}

/**
 * @description Scroller Elm should be displayed within Scroller component.  
 * It is a wrapper for Card and CardContent, with appropriate sx settings for scroll behavior.  Add optional sx to Card or CardContent if needed.
 */

export function ScrollerElm({children, cardSx={}, cardContentSx={}, ...props}: ScrollerElmProps){
    return (
        // overflow: 'visible'
        <Card sx={{
            scrollSnapAlign: 'start', 
            ...cardSx
        }}> 
            <CardContent sx={{...cardContentSx}}>
                {children}
            </CardContent>
        </Card>
    )
}