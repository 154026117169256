import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import FlashingDiv from './FlashingDiv';
import { Grid, Typography } from '@mui/material';


function FlashingKeyValDisplay() {

    return (
        <Stack sx={{ mx: "6px", my:.5, width: '100%', display: 'flex', justifyContent: 'space-between' }} direction="row">
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FlashingDiv sx={{ backgroundColor: grey[200], width: '10rem', borderRadius: 4, marginBottom: 0.5 }}>
                    <Typography>
                        &nbsp;
                    </Typography>
                </FlashingDiv>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FlashingDiv sx={{ backgroundColor: grey[200], width: '6rem', borderRadius: 4 }}>
                    <Typography>
                        &nbsp;
                    </Typography>
                </FlashingDiv>
            </Box>
        </Stack>
    )
}


export default function AboutSkeleton() {

    return (
            <Stack sx={{ width: "100%" }}>
                <FlashingDiv sx={{ textAlign: "center", fontSize: '2.4rem', backgroundColor: grey[200], borderRadius: 1, marginX: 10, marginBottom: 1 }}>
                    &nbsp;
                </FlashingDiv>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 0.5, gap: 1 }}>
                    <FlashingDiv sx={{ fontSize: '1.8rem', backgroundColor: grey[200], width: '6rem', borderRadius: 4 }}>
                        &nbsp;
                    </FlashingDiv>
                    <FlashingDiv sx={{ fontSize: '1.8rem', backgroundColor: grey[200], width: '6rem', borderRadius: 4 }}>
                        &nbsp;
                    </FlashingDiv>
                </Box>
                <Grid container sx={{ my: 3, width: '100%' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FlashingKeyValDisplay />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FlashingKeyValDisplay />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FlashingKeyValDisplay />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FlashingKeyValDisplay />
                    </Grid>
                </Grid>
           </Stack>
    );
}