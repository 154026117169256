import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box"; 
import Stack from "@mui/material/Stack"; 
import grey from '@mui/material/colors/grey'; 
import logo from "src/assets/insight-icon_190x191.png"
import { styled } from '@mui/material';
import { keyframes } from '@emotion/react';

const pulseAnimationKeyFrame = keyframes`
    50% {
        transform: scale(1.05, 1.05);
    }
`;
const PulseAnimation = styled(Box)({
    animation: `${pulseAnimationKeyFrame} 3s infinite ease`
});

const turnAnimationKeyFrame = keyframes`
    50% {
        transform: rotate(10deg);
    }
`;
const TurnAnimation = styled(Box)({
    animation: `${turnAnimationKeyFrame} 3s infinite ease`
});

const circleGrowKeyFrame = keyframes`
    0% {
        transform: scale(1, 1);
        opacity: 10%; 
    }, 
    20% {
        transform: scale(6, 6);
        opacity: 10%; 
    },
    70% {
        transform: scale(7, 7);
        opacity: 2%; 
    } 
    100% {
        transform: scale(7, 7);
        opacity: 0%; 
    } 

`;
const CircleGrowAnimation = styled(Box)({
    zIndex: -2, 
    animation: `${circleGrowKeyFrame} 3s infinite ease`
});


export default function LoginLoadingComponent() {
    return (
    <Stack sx={{width: '100%', height: '100vh'}} justifyContent="center" alignItems="center">
        <TurnAnimation>
            <PulseAnimation>
                <Box
                    component="img"
                    sx={{
                    minHeight: 200,
                    minWidth: 200,
                    }}
                    alt="Insight application logo."
                    src={logo}
                />
            </PulseAnimation>
        </TurnAnimation>        
        <CircleGrowAnimation sx={{position: 'absolute', height: '50px', width: '50px', borderRadius: '50%', background: 'radial-gradient(#ffffff, #293D74)'}}/>
        <Typography sx={{position: 'absolute', top: "60px",  color: grey[700], fontSize: '2rem', backgroundColor: 'white'}}><b>Authentication in progress...</b></Typography>
    </Stack>
    )
};