import ServicesLoading from "../components/Skeleton/ServicesLoading";
import ServicesDisplay from "src/components/Services/ServicesDisplay";
import { useCompanyIdFromPathAssertively } from "src/hooks/useCompanyIdFromPath";
import assertively from "src/util/assertively";
import { useEntitlements } from "src/hooks/useEntitlements";

export default function Services() {
    const companyId = useCompanyIdFromPathAssertively();
    const services = assertively(useEntitlements(companyId));

    if (services == null) {
        // loading
        return <ServicesLoading />;
    } else {
        // data success exists
        return <ServicesDisplay services={services} />;
    }
}
