import { useQuery } from "@tanstack/react-query";
import { AsyncStateIndeterminate, PublicationsReturn, PublicationsReturnIndeterminate, } from "src/types";
import { useBackend, useRegion } from "./useRegion";

export function usePublications(companyId: string): PublicationsReturnIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<PublicationsReturn, Error>({
        queryFn: () => backend.get<{ result: PublicationsReturn }>(`/getPublicationList/${companyId}`).then((res) => res.data.result),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["publications", companyId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}

type User = {
    id: string; 
    display: string; 
}

type UserIndeterminate = AsyncStateIndeterminate<User[]>; 

export function usePublicationUserList(publicationId:string, companyId:string): UserIndeterminate {
    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<User[], Error>({
        queryFn: () => backend.get<{ result: User[] }>(`/getPublicationUserList?accountSysId=${companyId}&publicationSysId=${publicationId}`)
          .then((res) => res.data.result),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["publications-users", companyId, publicationId, region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}

