import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';



/**
 * Taken from https://github.com/mui/material-ui/issues/10739#issuecomment-1001530270. 
 * May need adjustment later based on other comments in the thread.
 */
export default function useAppBarHeight(): number {
    const { mixins: { toolbar }, breakpoints } = useTheme();

    const toolbarDesktopQuery = breakpoints.up('sm');
    const toolbarLandscapeQuery = `${breakpoints.up('xs')} and (orientation: landscape)`;
    const isDesktop = useMediaQuery(toolbarDesktopQuery);
    const isLandscape = useMediaQuery(toolbarLandscapeQuery);

    let currentToolbarMinHeight;
    if (isDesktop) {
        currentToolbarMinHeight = toolbar[toolbarDesktopQuery];
    } else if (isLandscape) {
        // currentToolbarMinHeight = toolbar[toolbarLandscapeQuery];
        currentToolbarMinHeight = (toolbar[breakpoints.up('xs')] as any)[`@media (orientation: landscape)`];
    } else {
        currentToolbarMinHeight = toolbar;
    }
    return (currentToolbarMinHeight as ({ minHeight: number } | undefined))?.minHeight || 0;
    // return 64;
}