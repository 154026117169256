import { AxiosInstance } from "axios";
import { useLocation } from "react-router-dom";
import { backend, euBackend } from "../services/backend";

export type Region = typeof REGIONS[number];


export const REGIONS = ['us', 'eu'] as const;



export function useRegion(): Region | null {
    const pathRegion = useLocation().pathname.split("/")[1].toLowerCase() as Region;
    return REGIONS.includes(pathRegion) ? pathRegion : null;
}

export function useRegionAssertively(): Region {
    const region = useRegion();
    if (!region) throw Error("Region not found in path");
    return region;
}

export function useSupportedRegions(): any[] {
    return REGIONS.slice();
}

export function useBackend(): AxiosInstance {
    switch(useRegion()) {
        case 'us': return backend;
        case 'eu': return euBackend;
        default: throw Error("Region not found in path");
    }
}