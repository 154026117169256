import React, { useRef, useEffect } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { red, green, grey } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

import InvoiceOrderDetailsSkeleton from '../Skeleton/InvoiceOrderDetailsSkeleton';

import type { Order, OrderDetails } from '../../types';
import { useOrderDetails } from 'src/hooks/useOrders';



interface IOrdersProps {
    orders: Order[],
    numberOpen?: string,
    onChangeFn: (orderNumber: string) => void,
    isTouchScreen: boolean, 
    openOrders: string[]
}

export default function OrdersDisplay({ orders, numberOpen, onChangeFn, isTouchScreen, openOrders }: IOrdersProps) {
    const accordionSx = {
        boxShadow: 0.5,
        m: 1,
        border: 1,
        borderColor: '#e6e6e6',
        borderRadius: 1,
        backgroundColor: grey[50],
        '&:before': { display: 'none' }
    };
    const additionalSummarySx = isTouchScreen
        ? {}
        : { '&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' } };

    let scrollToOpenRef = useRef<HTMLDivElement>(null);

    // only control scroll on first render
    useEffect(() => {
        scrollToOpenRef.current?.scrollIntoView();
    }, []);

    return (
        !orders.length ? <Box sx={{ fontSize: '1.8rem', margin: 2 }}>No Orders Found.</Box> :
            <Box>
                {
                    orders.map((order, index) => {
                        const orderTotal = parseFloat(order.u_total_mrr_local) + parseFloat(order.u_total_one_time_local);
                        const orderStatusColor =
                            (order.state === 'Complete') ?
                                green[300] :
                                (order.state === 'Cancelled') ?
                                    red[300] :
                                    grey[300];

                        return (
                            <Accordion
                                ref={order.number === numberOpen ? scrollToOpenRef : null}
                                key={order.number}
                                sx={accordionSx}
                                expanded={openOrders.includes(order.number)}
                                data-testid={`testId-${index}`}
                                onChange={() => onChangeFn(order.number)}
                                TransitionProps={{ unmountOnExit: true }}
                            >
                                <AccordionSummary
                                    aria-controls={`${order.number}-content`}
                                    id={`${order.number}-header`}
                                    sx={{
                                        border: 0,
                                        padding: 0,
                                        // see overriding nested component styles for explanation on selector: 
                                        // https://mui.com/material-ui/customization/how-to-customize/#the-sx-prop
                                        '& .MuiAccordionSummary-content': {
                                            margin: 0
                                        },
                                        width: '100%',
                                        py: '7px',
                                        pl: '10px',
                                        pr: '5px',
                                        borderRadius: '4px',
                                        ...additionalSummarySx
                                    }}
                                    disableRipple={false}
                                >
                                    <Stack style={{ width: '100%', paddingLeft: 1, paddingRight: 1 }}>
                                        <Stack sx={{ width: '100%' }} flexDirection="row" justifyContent="space-between">
                                            <Box sx={{ fontSize: '1.2rem' }}>
                                                {new Date(order.sys_created_on_UTC_TZ).toLocaleDateString()}
                                            </Box>
                                            <Box sx={{ fontSize: '1.2rem' }}>
                                                {order.u_total_mrr_local_code}
                                            </Box>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                                            <Box sx={{ fontSize: '1.8rem', fontWeight: 'bold', textAlign: "left", overflowWrap: 'break-word', hyphens: 'manual' }}>
                                                {order.short_description}
                                            </Box>
                                            {/* <Chip sx={{marginLeft: '8px', marginRight: "4px", fontSize: "1.4rem", flexShrink: 2, minWidth: '0px', overflow: 'hidden'}} label={order.u_order_type} size="small"/> */}
                                            {/** Empty div with flexGrow to fill blank space */}
                                            <Box sx={{ flexGrow: 2 }}></Box>
                                            <Box sx={{ fontSize: '1.8rem', fontWeight: 'bold', marginLeft: 2 }}>
                                                {orderTotal.toLocaleString()}
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    {/* Box for Order Status */}
                                    <Tooltip title={order.state} arrow placement="top" >
                                        <Box aria-describedby={'orderStatusMarker'} sx={{ backgroundColor: orderStatusColor, width: '15px', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, marginLeft: 1, marginY: .5 }} />
                                    </Tooltip>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '0px', mt: 1 }}>
                                    <ExpandedOrder order={order} />
                                </AccordionDetails>
                            </Accordion>
                        )
                    })

                }
            </Box>
    );
}



interface IExpandedOrderProps {
    order: Order,
}

function ExpandedOrder({ order }: IExpandedOrderProps) {
    const orderDetails = useOrderDetails(order.sys_id);

    return (
        <Grid container sx={{ backgroundColor: '#EAEAEF', borderRadius: '4px' }}>
            <Grid item xs={0} sm={1}></Grid>
            <Grid item xs={12} sm={11}>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Date" value={new Date(`${order.sys_created_on_UTC_TZ}`).toLocaleDateString()} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <KeyValueDisplay label="Short Description" value={order.short_description} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Order #" value={order.number} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Contract Agreement Date" value={order.u_contract_agreement_date} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Opportunity Closed Date" value={order.u_opp_closed_date} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Type" value={order.u_order_type} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="State" value={order.state} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="MRR Increase" value={`${parseFloat(order.u_total_mrr_local).toLocaleString()} ${order.u_total_mrr_local_code}`} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Total One Time" value={`${parseFloat(order.u_total_one_time_local).toLocaleString()} ${order.u_total_one_time_local_code}`} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Agent" value={order.u_agent.display} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Master Agent" value={order.u_master_agent.display} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Assigned To" value={order.u_assigned_to.display} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Sales Engineer" value={order.u_sales_engineer.display} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Sales Person" value={order.u_sales_person.display} />
                    </Grid>
                    {
                        order.u_salesforce_id && <Grid item xs={12} sm={6} md={4}>
                            <KeyValueDisplay label="SalesForce Link" value={
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://thrivenetworks.lightning.force.com/lightning/r/Account/${order.u_salesforce_id}/view`}
                                >
                                    Link
                                </a>
                            } />
                        </Grid>
                    }
                </Grid>
                {
                    (orderDetails instanceof Error) ? <OrderDetailsError /> :
                    !orderDetails ? <Grid container><InvoiceOrderDetailsSkeleton count={5} /></Grid> :
                    !orderDetails.length ? <EmptyOrderDetails /> : 
                        <OrderDetailsList orderDetails={orderDetails} />
                }
            </Grid>
        </Grid>
    )
}


function OrderDetailsList({ orderDetails }: { orderDetails: OrderDetails[] }) {
    const vertCellPad = "8px";
    const horzCellPad = "5px";

    return (
        <Grid container>
            <Grid item xs={3} sx={{ paddingY: vertCellPad, paddingX: horzCellPad }}>
                Product ID
            </Grid>
            <Grid item xs={5} sx={{ paddingY: vertCellPad, paddingX: horzCellPad }}>
                Description
            </Grid>
            <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, textAlign: 'end' }}>
                Quantity
            </Grid>
            <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, textAlign: 'end' }}>
                Total Price
            </Grid>
            {
                orderDetails.map(orderItem => {
                    const _unitPrice = Number.parseFloat(orderItem.u_unit_price_local);
                    const unitPrice = Number.isNaN(_unitPrice) ? "—" : `${_unitPrice.toLocaleString()}`;
                    // order has a total price, but it was showing only reference currency.  Calculating with quantity and unit price instead 
                    // ** this is likely corrected by the time you're reading this note.  
                    const _totalPrice = Number.parseFloat(orderItem.quantity) * _unitPrice;
                    const totalPrice = Number.isNaN(_totalPrice) ? "—" : `${_totalPrice.toLocaleString()}`;

                    const totalPriceTooltip = parseInt(orderItem.quantity) && parseInt(orderItem.quantity) ? `${orderItem.quantity} x ${unitPrice} ${orderItem.u_unit_price_local_code}` : ""

                    return (
                        <React.Fragment key={orderItem.order_line_id}>
                            <Grid item xs={3} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, fontWeight: "medium", fontSize: '1.6rem' }}>
                                <Tooltip title={orderItem.u_product_id} placement="top-start">
                                    <Typography noWrap sx={{ fontWeight: "medium" }}>
                                        {orderItem.u_product_id}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={5} sx={{ paddingY: vertCellPad, paddingLeft: horzCellPad, paddingRight: "10px", fontSize: '1.6rem' }}>
                                <Tooltip title={orderItem.u_invoice_description} placement="top-start">
                                    <Typography noWrap sx={{ fontWeight: "medium" }}>
                                        {orderItem.short_description}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingLeft: horzCellPad, paddingRight: "10px", fontSize: '1.6rem', textAlign: 'end' }}>
                                <Tooltip title={orderItem.quantity} placement="top-end">
                                    <Typography noWrap sx={{ fontWeight: "medium" }}>
                                        {orderItem.quantity}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, fontWeight: "medium", fontSize: '1.6rem', textAlign: 'end' }}>
                                <Tooltip title={totalPriceTooltip} placement="top-end">
                                    <Typography noWrap sx={{ fontWeight: "medium" }}>
                                        {totalPrice} {totalPrice !== "—" ? orderItem.u_unit_price_local_code : ''}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </React.Fragment>
                    );
                })
            }
        </Grid>
    );
}



function OrderDetailsError() {
    return (
        <Typography variant='h6' sx={{ margin: 2 }}>
            <ErrorIcon /> Error requesting order details. Reload page to try again.
        </Typography>
    );
}


function EmptyOrderDetails() {
    return (
        <Typography variant='body2' sx={{ margin: 2 }}>
            No order details found.
        </Typography>
    );
}



interface KeyValueDisplayProps {
    label: any,
    value: any
}

function KeyValueDisplay(props: KeyValueDisplayProps) {
    const label = props.label;
    const value = props.value;
    return (
        <Stack sx={{ margin: "6px" }}>
            <Box>
                <Typography >
                    {label}
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {value}
                </Typography>
            </Box>
        </Stack>
    )
}
