import { DataCenterLocations } from "src/components/About/Locations";
import { Box, Container, useMediaQuery } from "@mui/material";

export default function DataCenters(){
    const largeScreen = useMediaQuery('(min-width:800px)');

    return (
        <Container>
            {
                largeScreen ? 
                <Box sx={{p:1, height: '650px'}}>
                    <DataCenterLocations />
                </Box> : 
                <Box sx={{p:1, height: '550px'}}>
                    <DataCenterLocations />
                </Box>
            }
        </Container>
    )
}