import { useQuery } from "@tanstack/react-query";
import { UserFromLogin, UserFromSN, UserFromSNIndeterminate } from "src/types";
import { useBackend, useRegion } from "./useRegion";
import {
    useMsal
} from "@azure/msal-react";

const { REACT_APP_USER_EMAIL } = process.env;

export function useUser(): UserFromSNIndeterminate {
    //get email, called username from auth object
    const { instance } = useMsal();
    const currentAcct = instance ? instance.getActiveAccount() as UserFromLogin | null : null; 
    const username = REACT_APP_USER_EMAIL ? REACT_APP_USER_EMAIL : currentAcct ? currentAcct.username : ""; 

    const region = useRegion();
    const backend = useBackend();
    const { status, data, error } = useQuery<UserFromSN, Error>({
        queryFn: () =>
            backend
                .get<{ result: UserFromSN }>(`/getUser/${username}`)
                .then((res) => res.data.result),
        //region is put in as a queryKey to prompt a refetch on region change
        queryKey: ["sn-user-data", region],
        staleTime: Infinity,
    });

    if (status === "pending") return null;
    if (status === "error") return error;
    else return data;
}
