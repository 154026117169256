import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Collapse, IconButton, IconButtonProps, Skeleton, Stack, styled, useMediaQuery, useTheme } from "@mui/material";
import { Publication } from "src/types";
import { grey, orange } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCompanyIdFromPathAssertively } from "src/hooks/useCompanyIdFromPath";
import { usePublicationUserList } from "src/hooks/usePublications";
import assertively from "src/util/assertively";


interface PublicationsDisplayProps {
    publications: Publication[];
    openPublications: string[];
    numberOpen: string;
    onChangeFn: (pubNumber: string) => void;
    isTouchScreen: boolean;
}

export function PublicationsDisplay({ publications, openPublications, numberOpen,
        onChangeFn, isTouchScreen }: PublicationsDisplayProps) {

    let scrollToOpenRef = useRef<HTMLDivElement>(null);

    // only control scroll on first render
    useEffect(() => {
        scrollToOpenRef.current?.scrollIntoView();
    }, [])

    return (
        <Box>
            {publications.map((publication) => {
                return (
                    <PublicationAccordion publication={publication} key={publication.publication.id}
                        numberOpen={numberOpen} openPublications={openPublications}
                        onChangeFn={onChangeFn} isTouchScreen={isTouchScreen} scrollToOpenRef={scrollToOpenRef}
                    />
                )
            })}
        </Box>
    );
}

interface PublicationAccordionProps {
    publication: Publication
    numberOpen: string
    openPublications: string[]
    scrollToOpenRef: React.RefObject<HTMLDivElement>
    onChangeFn: (u_number: string) => void
    isTouchScreen: boolean

    
}

function PublicationAccordion({ publication, numberOpen, onChangeFn, isTouchScreen,
        scrollToOpenRef, openPublications }: PublicationAccordionProps) {
    const [expandUsers, setExpandUsers] = useState(false);
    const companyId = useCompanyIdFromPathAssertively(); 
    const isScreenSmall = useMediaQuery(useTheme().breakpoints.down("md"));
    const chipColumnWidth = isScreenSmall ? "50px" : "180px"; 


    const accordionSx = {
        boxShadow: 0.5,
        border: 1,
        borderColor: '#e6e6e6',
        borderRadius: 1,
        margin: 1,
        '&:before': { display: 'none' },
        backgroundColor: grey[50]
    };
    const additionalSummarySx = isTouchScreen
        ? {}
        : { '&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' } };

    const pubDate: Date = new Date(`${publication.publish_date.split(" ")[0]}T12:00:00`);

    const previewRecipientsArr = publication.users
        .sort((a,b) => a.display < b.display ? -1 : 1)
        .map((user) => user.display);
    

    const sanitizeSnippet = { __html: DOMPurify.sanitize(publication.text || "", { FORBID_TAGS: ['strong'], FORBID_ATTR: ['style'] }) };

    const showMoreUsersCt = publication.userCt - publication.users.length;

    return (
        <Accordion
            key={publication.publication.id}
            ref={publication.number === numberOpen ? scrollToOpenRef : null}
            sx={accordionSx}
            expanded={openPublications.includes(publication.number)}
            onChange={(e, expanded) => onChangeFn(publication.number)}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary
                sx={{
                    width: '100%',
                    paddingX: '5px',
                    paddingY: '6px',
                    borderRadius: '4px',
                    '& .MuiAccordionSummary-content': {
                        margin: '0px'
                    },
                    ...additionalSummarySx
                }}
                disableRipple={false}
            >
                <Stack sx={{ width: '100%', px: 1 }}>
                    { isScreenSmall && publication.skip_notifications === 'true' && 
                        <Box sx={{ width: '100%' }} >
                            <Box sx={{ fontSize: '1.2rem', color: orange[800], fontWeight: "bold" }}>
                                Portal Announcement Only
                            </Box>
                        </Box> 
                    }

                    <Stack sx={{ width: '100%' }} flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box sx={{ fontSize: '1.2rem' }}>
                            {pubDate.toLocaleDateString()}
                        </Box>
                        <Box sx={{ fontSize: '1.2rem', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{mr:.5}}>{publication.userCt}</Box>
                            <PersonIcon sx={{fontSize: '20px'}} />
                        </Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                        <Box sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
                            {publication.number} - {publication.short_description}
                        </Box>
                        {/** Empty div with flexGrow to fill blank space */}
                        <Box sx={{ flexGrow: 2 }}></Box>
                        <Box sx={{alignSelf: 'end', minWidth: chipColumnWidth, my:.5, display: 'flex', justifyContent: 'end'}}>
                            {
                                !isScreenSmall && publication.skip_notifications === 'true' &&
                                    <Chip 
                                        label={"Portal Announcement Only"} size="small" 
                                        sx={{backgroundColor: orange[100], fontWeight: 'bold'}} />
                            }
                        </Box>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px', mt: 1, backgroundColor: '#EAEAEF', borderRadius: '4px' }}>
                <Box sx={{p:2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'}}>
                    <Box sx={{fontSize: '1.4rem', fontWeight: 'bold', mb:.5}}>Recipients: ({publication.userCt})</Box>
                    <Box sx={{fontSize: '1.2rem', display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                        <UserPills userNames={previewRecipientsArr} />
                        {
                            showMoreUsersCt > 0 &&
                                <Chip 
                                    sx={{pl:1, mb: .5}}
                                    size="small" 
                                    label={`Show ${showMoreUsersCt} additional`}   
                                    onClick={() => setExpandUsers(!expandUsers)}
                                    onDelete={() => setExpandUsers(!expandUsers)}
                                    deleteIcon={
                                        <ExpandMore
                                            expand={expandUsers}
                                            aria-expanded={expandUsers}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </ExpandMore>    
                                    }
                                />
                        }
                    </Box>

                    <Collapse in={expandUsers} timeout="auto" unmountOnExit>
                        <RemainingRecipients companyId={companyId} publicationId={publication.publication.id} 
                            existingUsers={previewRecipientsArr} expectedUserCt={showMoreUsersCt} />

                    </Collapse>
                    <Box dangerouslySetInnerHTML={sanitizeSnippet} sx={{ fontSize: '1.2rem', backgroundColor: grey[50], borderRadius: 2, p:1, my:1 }} />
                </Box>

            </AccordionDetails>
        </Accordion>
    )
}

interface RemainingRecipientsProps {
    companyId: string;
    publicationId: string;
    existingUsers: string[];
    expectedUserCt: number;
}

function RemainingRecipients({ companyId, publicationId, existingUsers, expectedUserCt }: RemainingRecipientsProps) {

    const fullList = assertively(usePublicationUserList(publicationId, companyId))
    if (!fullList) return <UserPills userNames={[...Array(Math.min(expectedUserCt, 12)).keys()].map((ind) => "skeleton" + ind)} loading={true} />
    const userNames = fullList.map((user) => user.display).filter((name) => !existingUsers.includes(name));    
    return (
        <UserPills userNames={userNames} loading={false} />
    )
}



interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

interface UserPillsProps {
    userNames: string[]
    loading?: boolean
}

function UserPills({ userNames, loading }: UserPillsProps) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {userNames.map((name) => {
                const chipLabel = loading ? <Skeleton variant="rounded" width="80px" height="10px" /> : name;
                return (
                    <Chip key={name} size="small" color="secondary" label={chipLabel} sx={{mr:.5, mb: .5}}/>
                )
            })}
        </Box>
    )
}