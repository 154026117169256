import { grey } from '@mui/material/colors'; 
import FlashingDiv from '../Skeleton/FlashingDiv';
import Grid from '@mui/material/Grid';

export type ContractDetailsSkeletonProps = {
    count: number
}


export default function ContractDetailsSkeleton(props: ContractDetailsSkeletonProps){
    const count = props.count; 
    //@ts-ignore
    const listOfSkeletons = Array(count).fill().map((x, index) => <IndSkeleton key={String(index)}/>)
    return (
        <>{listOfSkeletons}</>
    )
}

function IndSkeleton() {
    const vertCellPad = "8px"; 
    const horzCellPad = "5px"; 

    return (
        <>
            <Grid item xs={12} sx={{width: 1, paddingY: vertCellPad, paddingX: horzCellPad}}>
                <FlashingDiv sx={{fontSize: '1.2rem', backgroundColor: grey[200], borderRadius: 1, marginBottom: 1}}>
                    &nbsp;
                </FlashingDiv>
            </Grid>
        </>
    )   
}