import type { CompanyOption } from "src/types";



/**
 * Standardized function for filtering and sorting companies by name
 * @param companies
 * @param searchStr 
 * @param limit - optional for performance, limit the number of results returned
 */
export default function filterAndSortCompanies(companies: CompanyOption[], searchStr: string, limit: number = Infinity): CompanyOption[] {
    const compareStr = searchStr.toLowerCase().trim();
    const searchStrLen = compareStr.length;
    const matchesSearch = (c: CompanyOption) => c.name.toLowerCase().includes(compareStr);

    const filteredAndSorted: CompanyOption[] = [];
    
    for (const c of companies) {
        if (matchesSearch(c)) filteredAndSorted.push(c);
        if (filteredAndSorted.length >= limit) break;
    }

    return filteredAndSorted
        .sort((a, b) => {
            return a.name.slice(0, searchStrLen).toLowerCase() === compareStr
                && b.name.slice(0, searchStrLen).toLowerCase() !== compareStr
                ? -1 : 1;
        });
}